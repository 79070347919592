/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 31/3/2021
 * @Example
 */

import { Button, Typography, ButtonIcon } from 'diginet-core-ui/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Config from '../../../../../config';
import Modal from '../../../../common/modal/modal';

W39F3005DateModal.propTypes = {
    mode: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    classes: PropTypes.object,
    onSelect: PropTypes.func,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
};

W39F3005DateModal.defaultProps = {
    mode: 'month',
    open: false,
    onClose: null,
    onSelect: null,
    dateFrom: '',
    dateTo: '',
};

function W39F3005DateModal(props) {
    const { mode, open, onClose, classes, onSelect, dateFrom, dateTo } = props;

    const [year, setYear] = useState(() => {
        return moment().year();
    });
    const [month, setMonth] = useState(null);
    const [quarter, setQuarter] = useState(null);

    const [yearFrom, setYearFrom] = useState(() => {
        return moment().year() - 5;
    });

    const [yearTo, setYearTo] = useState(() => {
        return moment().year();
    });

    const [selectedYear, setSelectedYear] = useState(null);

    let data = {
        PeriodName: '',
        DateFrom: null,
        DateTo: null,
    }

    useEffect(() => {
        const df = moment(dateFrom, 'DD/MM/YYYY');
        const dt = moment(dateTo, 'DD/MM/YYYY');

        switch (mode) {
            case "month":
                if (df.month() !== 'NaN' && dt.month() !== 'NaN' && df.month() === dt.month()) {
                    setMonth(df.month() + 1);
                    if (df.year() !== 'NaN' && dt.year() !== 'NaN' && df.year() === dt.year()) {
                        setYear(df.year());
                    }
                }
                break;
            case "year":
                let y = null;
                if (df.year() !== 'NaN' && dt.year() !== 'NaN' && df.year() === dt.year()) {
                    y = df.year();
                }
                if (y) {
                    let cy = moment().year();
                    while (cy >= y) {
                        cy -= 6;
                    }
                    setSelectedYear(y);
                    setYearFrom(cy + 1);
                    setYearTo(cy + 6);
                }
                break;
            case "quarter":
                if (df.month() !== 'NaN' && dt.month() !== 'NaN') {
                    if (df.month() === 0 && dt.month() === 2) {
                        setQuarter(1);
                    }
                    else if (df.month() === 3 && dt.month() === 5) {
                        setQuarter(2);
                    }
                    else if (df.month() === 6 && dt.month() === 8) {
                        setQuarter(3);
                    }
                    else if (df.month() === 9 && dt.month() === 11) {
                        setQuarter(4);
                    }

                    if (df.year() !== 'NaN' && dt.year() !== 'NaN' && df.year() === dt.year()) {
                        setYear(df.year());
                    }
                }
                break;
            default:
                break;
        };
    }, [mode, dateFrom, dateTo]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const renderContent = () => {
        let content = <></>;
        switch (mode) {
            case "month":
                content = <>
                    <div className={classes.divSelectYear}>
                        <ButtonIcon circular viewType={'text'} name={'ArrowDoubleLeft'} onClick={(e) => handleClick('doubleLeft')} />
                        <ButtonIcon circular viewType={'text'} name={'ArrowLeft'} onClick={(e) => handleClick('left')} />
                        <Typography type='h3' color={'#111D5E'}>{year}</Typography>
                        <ButtonIcon circular viewType={'text'} name={'ArrowRight'} onClick={(e) => handleClick('right')} />
                        <ButtonIcon circular viewType={'text'} name={'ArrowDoubleRight'} onClick={(e) => handleClick('doubleRight')} />
                    </div>
                    <div className={classes.divListSpan}>
                        {[...Array(12).keys()].map(i =>
                            <span key={i} className={`${classes.spanMonth} ${month === (i + 1) && classes.selected}`} onClick={(e) => handleClick('selectMonth', i + 1)}>
                                {`${Config.lang('Thang')} ${i + 1}`}
                            </span>
                        )}
                    </div>
                </>;
                break;
            case "year":
                content = <>
                    <div className={classes.divSelectYear}>
                        <ButtonIcon circular viewType={'text'} name={'ArrowDoubleLeft'} onClick={(e) => handleClick('doubleLeftYR')} />
                        <ButtonIcon circular viewType={'text'} name={'ArrowLeft'} onClick={(e) => handleClick('leftYR')} />
                        <Typography type='h3' color={'#111D5E'}>{`${yearFrom}-${yearTo}`}</Typography>
                        <ButtonIcon circular viewType={'text'} name={'ArrowRight'} onClick={(e) => handleClick('rightYR')} />
                        <ButtonIcon circular viewType={'text'} name={'ArrowDoubleRight'} onClick={(e) => handleClick('doubleRightYR')} />
                    </div>
                    <div className={classes.divListSpan}>
                        {[...Array(yearTo - yearFrom + 1).keys()].map(i =>
                            <span key={i} className={`${classes.spanYear} ${selectedYear === (yearFrom + i) && classes.selected}`} onClick={(e) => handleClick('selectYear', yearFrom + i)}>
                                {yearFrom + i}
                            </span>
                        )}
                    </div>
                </>;
                break;
            case "quarter":
                content = <>
                    <div className={classes.divSelectYear}>
                        <ButtonIcon circular viewType={'text'} name={'ArrowDoubleLeft'} onClick={(e) => handleClick('doubleLeft')} />
                        <ButtonIcon circular viewType={'text'} name={'ArrowLeft'} onClick={(e) => handleClick('left')} />
                        <Typography type='h3' color={'#111D5E'}>{year}</Typography>
                        <ButtonIcon circular viewType={'text'} name={'ArrowRight'} onClick={(e) => handleClick('right')} />
                        <ButtonIcon circular viewType={'text'} name={'ArrowDoubleRight'} onClick={(e) => handleClick('doubleRight')} />
                    </div>
                    <div className={classes.divListSpan}>
                        {[...Array(4).keys()].map(i =>
                            <span key={i} className={`${classes.spanQuarter} ${quarter === (i + 1) && classes.selected}`} onClick={(e) => handleClick('selectQuarter', i + 1)}>
                                {`${Config.lang('Quy')} ${i + 1}`}
                            </span>
                        )}
                    </div>
                </>;
                break;
            default:
                break;
        };
        return content;
    };

    const handleClick = (type, value) => {
        switch (type) {
            case "doubleLeft":
                setYear(year => year - 5);
                switch (mode) {
                    case 'month':
                        setMonth(null);
                        break;
                    case 'quarter':
                        setQuarter(null);
                        break;
                    default:
                        break;
                };
                break;
            case "left":
                setYear(year => year - 1);
                switch (mode) {
                    case 'month':
                        setMonth(null);
                        break;
                    case 'quarter':
                        setQuarter(null);
                        break;
                    default:
                        break;
                };
                break;
            case "doubleRight":
                setYear(year => year + 5);
                switch (mode) {
                    case 'month':
                        setMonth(null);
                        break;
                    case 'quarter':
                        setQuarter(null);
                        break;
                    default:
                        break;
                };
                break;
            case "right":
                setYear(year => year + 1);
                switch (mode) {
                    case 'month':
                        setMonth(null);
                        break;
                    case 'quarter':
                        setQuarter(null);
                        break;
                    default:
                        break;
                };
                break;
            case "doubleLeftYR":
                setYearFrom(yearFrom => yearFrom - 12);
                setYearTo(yearTo => yearTo - 12);
                setSelectedYear(null);
                break;
            case "leftYR":
                setYearFrom(yearFrom => yearFrom - 6);
                setYearTo(yearTo => yearTo - 6);
                setSelectedYear(null);
                break;
            case "doubleRightYR":
                setYearFrom(yearFrom => yearFrom + 12);
                setYearTo(yearTo => yearTo + 12);
                setSelectedYear(null);
                break;
            case "rightYR":
                setYearFrom(yearFrom => yearFrom + 6);
                setYearTo(yearTo => yearTo + 6);
                setSelectedYear(null);
                break;
            case "selectMonth":
                setMonth(month === value ? null : value);
                break;
            case "selectQuarter":
                setQuarter(quarter === value ? null : value);
                break;
            case "selectYear":
                setSelectedYear(selectedYear === value ? null : value);
                break;
            case "confirm":
                let date;
                switch (mode) {
                    case "month":
                        if (month) {
                            date = moment(`${month}/${year}`, 'MM/YYYY');
                            data = {
                                PeriodName: `${('0' + month).slice(-2)}-${year}`,
                                DateFrom: date.startOf('month').format('DD/MM/YYYY'),
                                DateTo: date.endOf('month').format('DD/MM/YYYY'),
                            };
                        }
                        else {
                            data = {
                                PeriodName: '',
                                DateFrom: null,
                                DateTo: null,
                            };
                        }
                        onSelect(data);
                        break;
                    case "year":
                        if (selectedYear) {
                            date = moment(selectedYear, 'YYYY');
                            data = {
                                PeriodName: selectedYear,
                                DateFrom: date.startOf('year').format('DD/MM/YYYY'),
                                DateTo: date.endOf('year').format('DD/MM/YYYY'),
                            };
                        }
                        else {
                            data = {
                                PeriodName: '',
                                DateFrom: null,
                                DateTo: null,
                            };
                        }
                        onSelect(data);
                        break;
                    case "quarter":
                        if (quarter) {
                            date = moment(`${year}`, 'YYYY');
                            data = {
                                PeriodName: `${('Q' + quarter)}-${year}`,
                                DateFrom: date.quarter(quarter).startOf('quarter').format('DD/MM/YYYY'),
                                DateTo: date.quarter(quarter).endOf('quarter').format('DD/MM/YYYY'),
                            };
                        }
                        else {
                            data = {
                                PeriodName: '',
                                DateFrom: null,
                                DateTo: null,
                            };
                        }
                        onSelect(data);
                        break;
                    default:
                        break;
                };
                break;
            default:
                break;
        }
    };

    let title = "";
    switch (mode) {
        case "month":
            title = Config.lang('Thang');
            break;
        case "year":
            title = Config.lang('Nam');
            break;
        case "quarter":
            title = Config.lang('Quy');
            break;
        default:
            break;
    };

    return (
        <Modal title={title} open={open} fullWidth={true} maxWidth={'xs'} onClose={handleClose}>
            <Modal.Content className={classes.modalContent}>
                {renderContent()}
                <Button
                    color={'info'}
                    viewType={'filled'}
                    text={Config.lang("Chon")}
                    onClick={e => handleClick('confirm')}
                />
            </Modal.Content>
        </Modal>
    );
}

export default W39F3005DateModal;