/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/4/2021
 * @Example
 */

import { Button, DatePicker, Modal, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput, Col, Row } from 'diginet-core-ui/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import { MForm } from '../../../../common/form-material';
import History from '../../../../libs/history';
import { replaceAndParseToNumber } from './commons';
import _ from "lodash";

W39F3002AddTR.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    handleOpenPopup: PropTypes.func,
    goalProps: PropTypes.object,
    masterProps: PropTypes.object
};

W39F3002AddTR.defaultProps = {
    classes: {},
    open: false,
    handleOpenPopup: null,
    goalProps: {},
    masterProps: {}
};

let captions = {
    GoalName: 'Ten',
    ValidDateFrom: 'Ngay_bat_dau',
    ValidDateTo: 'Ngay_hoan_thanh',
    Proportion: 'Trong_so',
    Description: 'Mo_ta',
};

function W39F3002AddTR(props) {
    const dataDynamicRef = useSelector((state) => state.W39F3002?.dataDynamicRef ?? []);
    const { open, handleOpenPopup, goalProps } = props;

    const [data, setData] = useState({
        GoalName: "",
        Description: "",
        Proportion: null,
        ValidDateFrom: null,
        ValidDateTo: null
    });
    const [error, setError] = useState({});
    const [loadingSave, setLoadingSave] = useState(false);

    const typingRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        loadObjectRefs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(dataDynamicRef)) {
            const dynamicVal = dataDynamicRef.map(item => ({ [item?.RefID]: item?.RefID.slice(0, 3) === "Num" ? null : "" }));
            setData({ ...data, ...Object.assign({}, ...dynamicVal) });// set default data
            const dynamicCap = dataDynamicRef.map(item => {
                let vl = item?.RefID;
                    if(item?.RefID.includes('NumKeyRef0')){
                        vl = vl?.replace('NumKeyRef0','tham_chieu_so_%')+'%';
                    }
                    else if(item?.RefID.includes('StrKeyRef0')){
                        vl = vl.replace('StrKeyRef0','tham_chieu_chuoi_%')+'%'
                    }
                return { [item?.RefID]: vl };
            });
            captions = { ...captions, ...Object.assign({}, ...dynamicCap) };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataDynamicRef]);

    const loadObjectRefs = () => {
        const params = {
            Type: "Key"
        };
        dispatch(W39F3002Actions.getObjectRef(params, (err) => {
            if (err) return Config.popup.show('ERROR', err);
        }));
    };

    const handleChange = (key, value) => {
        switch (key) {
            case 'GoalName':
            case 'Proportion':
                if (!!!value || value.length === 0) {
                    setError({ ...error, [key]: Config.lang('Truong_nay_bat_buoc_nhap') });
                } else {
                    setError({ ...error, [key]: '' });
                }
                setData({ ...data, [key]: value });
                break;
            case 'ValidDateFrom':
            case 'ValidDateTo':
                if (!value || value.length === 0) {
                    setError({ ...error, [key]: Config.lang('Truong_nay_bat_buoc_nhap') });
                } else {
                    setError({ ...error, [key]: '' });
                }
                setData({ ...data, [key]: value });
                break;
            case 'Description':
                if (typingRef.current) {
                    clearInterval(typingRef.current);
                }

                typingRef.current = setTimeout(() => {
                    setData({ ...data, [key]: value });
                }, 500);
                break;
            default:
                setData({ ...data, [key]: value });
                break;
        }
    };

    const handleSave = () => {
        setLoadingSave(true);
        const validateRules = [
            {
                name: 'GoalName',
                rules: ['isRequired'],
                value: data?.GoalName ?? '',
            },
            {
                name: 'ValidDateFrom',
                rules: ['isRequired'],
                value: data?.ValidDateFrom ?? '',
            },
            {
                name: 'ValidDateTo',
                rules: ['isRequired'],
                value: data?.ValidDateTo ?? '',
            },
        ];
        if(goalProps?.IsBalanceKeyProportion === 0) {
            validateRules.push({
                name: 'Proportion',
                rules: ['isRequired'],
                value: data?.Proportion ?? '',
            })
        }

        const validateForm = MForm.formValidation(validateRules);

        if (Object.keys(validateForm).length > 0) {
            setError(validateForm);
            setLoadingSave(false);
            return;
        }

        const param = {
            ...data,
            Proportion: replaceAndParseToNumber(data?.Proportion ?? 0),
            ...goalProps,
        };
        delete param?.IsBalanceKeyProportion;
        dispatch(W39F3002Actions.addResult(param, async (err, dt) => {
            setLoadingSave(false);
            if (err) {
                return Config.popup.show("ERROR", err);
            } else if (dt) {
                if (dt?.Status === 0) {
                    const goalID = dt?.GoalID ?? '';
                    await saveHistory(goalID);
                    handleOpenPopup('addTR', false, { GoalID: dt?.GoalID ?? '' });
                    Config.notify.show('success', dt?.Message || Config.lang('Them_ket_qua_then_chot_thanh_cong'), 2000);
                } else if (dt?.Status === 1) {
                    Config.popup.show("ERROR", dt?.Message);
                    return false;
                }
            }
        }));
    };

    const saveHistory = async (goalID) => {
        if (!data) return false;
        let dataHis = data;
        Object.keys(data).forEach(name => {
            if (data[name] === null) dataHis = { ...dataHis, [name]: "" };
        });
        if (goalID) {
            const options = {
                data: dataHis,
                captions: captions,
                action: 0,
                ModuleID: 'D39',
                TransID: goalID ?? '',
                TransactionID: 'W39F3002',
                TransactionName: 'Thông tin mục tiêu'
            };
            const history = new History(options);
            // console.log("===========HIS=========>", history.get());
            if (history.get().length > 0) {
                await history.save();
            }
        }
        else {
            return Config.notify.show('error', Config.lang('Luu_lich_su_khong_thanh_cong'), 2000);
        }
    };

    const renderDynamicRef = () => {
        return dataDynamicRef.filter(item => item?.IsUsed === 1).map((obj, idx) => {
            const { Name = "", RefID = "" } = obj;
            return (
                RefID.slice(0, 3) === "Num" ?
                    <Col xs={12} sm={12} md={6} key={idx}>
                        <NumberInput
                            key={idx}
                            disabledNegative
                            decimalDigit={4}
                            inputProps={{
                                maxLength: 19,
                            }}
                            thousandSeparator={","}
                            onChange={(e) => handleChange(RefID, e?.value)}
                            value={data?.[RefID] ?? null}
                            label={Name}
                        />
                    </Col>
                    :
                    <Col xs={12} key={idx}>
                        <TextInput
                            key={idx}
                            onChange={(e) => handleChange(RefID, e?.target?.value)}
                            value={data?.[RefID] || ""}
                            label={Name}
                        />
                    </Col>
            )
        });
    };

    return (
        <Modal width={'50%'} style={{ minWidth: 250 }} open={open} onClose={(e) => handleOpenPopup('addTR', false, false)}>
            <ModalHeader>{Config.lang('Them_ket_qua_then_chot')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12}>
                        <TextInput
                            onChange={(e) => handleChange('GoalName', e.target.value)}
                            value={data?.GoalName ?? ''}
                            label={Config.lang('Ten')}
                            required
                            error={error && error['GoalName']}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <DatePicker
                            label={Config.lang('Ngay_bat_dau')}
                            required
                            clearAble
                            actionIconAt={'start'}
                            max={data?.ValidDateTo ?? moment().format('YYYY/MM/DD')}
                            placeholder={'DD/MM/YYYY'}
                            displayFormat={'DD/MM/YYYY'}
                            value={data?.ValidDateFrom ?? ''}
                            viewType={'underlined'}
                            onChange={(e) => handleChange('ValidDateFrom', e.value)}
                            error={error && error['ValidDateFrom']}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <DatePicker
                            label={Config.lang('Ngay_hoan_thanh')}
                            required
                            clearAble
                            actionIconAt={'start'}
                            min={data?.ValidDateFrom}
                            placeholder={'DD/MM/YYYY'}
                            displayFormat={'DD/MM/YYYY'}
                            value={data?.ValidDateTo ?? ''}
                            viewType={'underlined'}
                            onChange={(e) => handleChange('ValidDateTo', e.value)}
                            error={error && error['ValidDateTo']}
                        />
                    </Col>
                    <Col xs={12}>
                        <NumberInput
                            disabled={goalProps?.IsBalanceKeyProportion === 1}
                            onChange={(e) => handleChange('Proportion', e.value)}
                            value={data?.Proportion ?? ''}
                            label={Config.lang('Trong_so')}
                            disabledNegative
                            required={goalProps?.IsBalanceKeyProportion === 0}
                            decimalDigit={4}
                            inputProps={{
                                maxLength: 19,
                            }}
                            error={error && error['Proportion']}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextInput
                            onChange={(e) => handleChange('Description', e.target.value)}
                            value={data?.Description ?? ''}
                            label={Config.lang('Mo_ta')}
                            multiline
                            style={{ marginBottom: 10 }}
                            error={error && error['Description']}
                        />
                    </Col>
                    {dataDynamicRef && renderDynamicRef()}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button viewType={'filled'} loading={loadingSave} color={'info'} startIcon={'Save'} onClick={() => handleSave()} text={Config.lang('Luu')} />
            </ModalFooter>
        </Modal>
    );
}

export default W39F3002AddTR;
