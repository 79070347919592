import React, {useState, useRef, useEffect} from "react";
import Config from "../../../../config";
import {Col, Row} from 'react-bootstrap';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Dropdown,
    Button,
    DatePicker,
    TextInput,
    NumberInput,
} from 'diginet-core-ui/components';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Api from "../../../../services/api";
import _ from "lodash";
import moment from "moment";
import W39F3015 from "../W39F3015/W39F3015";
import History from '../../../libs/history';

const styles = () => ({
    fontWeightBold: {
        fontWeight: "bold !important",
    }
});

const W39F3004 = ({data = {}, onClose, open, type = 0}) => {

    const [dataForm, setDataForm] = useState({});
    const [dataCboAppStatus, setDataCboAppStatus] = useState([]);
    const [dataCompute, setDataCompute] = useState([]);
    const [errors, setErrors] = useState({});
    const [fieldName, setFieldName] = useState("");
    const [isOpenW39F3015, setOpenW39F3015] = useState(false);

    const [isLoadingForm, setLoadingForm] = useState(false);
    const [isLoadingCboAppStatus, setLoadingCboAppStatus] = useState(false);

    const getPropsData = {
        GoalID: _.get(data, "GoalKeyName[0]", _.get(data, "GoalID", "")),
        ComputeMethod: _.get(data, "ComputeMethod", ""),
        Type: _.get(data, "Type", ""),
    };

    const handleFormatDate = (date, format) => moment(date).isValid() ? moment.utc(date).format(format) : "";

    const handleParseToIntNumber = (number) => _.chain(number).toString().replace(/[ ,.]/g, "").toNumber().value();

    const getStateData = {
        GoalID: _.get(dataForm, "GoalID", ""),
        GoalName: _.get(dataForm, "GoalName", ""),
        IsImptEdit: _.toNumber(_.get(dataForm, "IsImptEdit", 0)),
        IsAllEdit: _.toNumber(_.get(dataForm, "IsAllEdit", 0)),
        IsEditMethod: _.toNumber(_.get(dataForm, "IsEditMethod", 0)),
        IsEditResult: _.toNumber(_.get(dataForm, "IsEditResult", 0)),
        IsCancel: _.get(dataForm, "IsCancel", 0),
        Status: _.get(dataForm, "StatusID", ""),
        ComputeMethod: _.get(dataForm, "ComputeMethod", ""),
        IsProportionEdit: _.get(dataForm, "IsProportionEdit", 0),
        ValidDateFrom: handleFormatDate(_.get(dataForm, "ValidDateFrom", null), "YYYY-MM-DD"),
        ValidDateTo: handleFormatDate(_.get(dataForm, "ValidDateTo", null), "YYYY-MM-DD"),
        Proportion: _.get(dataForm, "Proportion", 0),
        Value: _.get(dataForm, "Value", 0),
        Type: _.get(dataForm, "Type", ""),
        IsBalanceObjectProportion: _.get(dataForm, "IsBalanceObjectProportion", 0),
        IsBalanceKeyProportion: _.get(dataForm, "IsBalanceKeyProportion", 0),
    };

    const timerFormLoading = useRef(0);
    const timerCboAppStatusLoading = useRef(0);
    const oldData = useRef(null);
    const isUpdated = useRef(false);

    useEffect(() => {
        loadForm();
        loadComputeMethod();

        return () => {
            setTimeoutLoading(timerFormLoading);
            setTimeoutLoading(timerCboAppStatusLoading);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let fieldError = {};
        if (fieldName) {
            const fieldInValid = validate(fieldName);
            fieldError = {...errors, ...fieldInValid};
            if (_.isEmpty(fieldInValid)) fieldError = {..._.omit(errors, fieldName)}
        }
        setErrors(fieldError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldName, getStateData.Value, getStateData.Proportion])

    const validate = (name) => {
        let textError = "";
        if (_.isNil(getStateData[name])) textError = Config.lang("Loi_khong_xac_dinh");
        if (["Proportion", "Value"].includes(name) && _.toNumber(getStateData[name]) < 0) textError = " ";
        if (getStateData[name] === "") textError = Config.lang("Truong_nay_bat_buoc_nhap");
        return textError ? {[name]: textError} : {};
    }

    const setTimeoutLoading = (timer, setStateLoading = null) => {
        if (timer.current) clearTimeout(timer.current);
        if (!_.isNil(setStateLoading)) {
            timer.current = setTimeout(() => {
                setStateLoading(false)
            }, 300);
        }
    };

    const getStatusName = (id) => _.get(_.filter(dataCboAppStatus, item => _.get(item, "StatusID", "") === id), "[0]StatusName", "")

    const saveHistory = async (isDestroyTarget = false) => {
        const _data = {
            GoalName: getStateData.GoalName,
            GoalNameAndID: _.join(_.compact([getStateData.GoalName, getStateData.GoalID]), "-"),
            ValidDateFrom: getStateData.ValidDateFrom,
            ValidDateTo: getStateData.ValidDateTo,
            Proportion: handleParseToIntNumber(getStateData.Proportion),
            Value: handleParseToIntNumber(getStateData.Value),
            StatusName: getStatusName(getStateData.Status)
        };

        const _dataOld = {
            GoalName: _.get(oldData, "current.GoalName", ""),
            GoalNameAndID: _.join(_.compact([_.get(oldData, "current.GoalName", ""), _.get(oldData, "current.GoalID", "")]), "-"),
            ValidDateFrom: handleFormatDate(_.get(oldData, "current.ValidDateFrom", null), "YYYY-MM-DD"),
            ValidDateTo: handleFormatDate(_.get(oldData, "current.ValidDateTo", null), "YYYY-MM-DD"),
            Proportion: _.get(oldData, "current.Proportion", 0),
            Value: _.get(oldData, "current.Value", 0),
            StatusName: getStatusName(oldData.StatusID)
        };

        const captions = {
            GoalName: "ten",
            GoalNameAndID: "ten_muc_tieu",
            ValidDateFrom: "ngay_bat_dau",
            ValidDateTo: "ngay_hoan_thanh",
            Proportion: "trong_so",
            Value: "ket_qua",
            StatusName: "trang_thai"
        };

        const options = {
            data: isDestroyTarget ? _.pick(_data, ["StatusName", "GoalNameAndID"]) : _.omit(_data, "GoalNameAndID"),
            dataCompare: isDestroyTarget ? _.pick(_data, ["StatusName", "GoalNameAndID"]) : _.omit(_dataOld, "GoalNameAndID"),
            captions: isDestroyTarget ? _.pick(captions, ["StatusName", "GoalNameAndID"]) : _.omit(captions, "GoalNameAndID"),
            action: 1,
            ModuleID: "D39",
            TransactionID: "W39F3004",
            TransactionName: "Thông tin mục tiêu",
            TransID: "29AP0Y000000149",
        };

        const history = new History(options);
        if (history.get().length > 0) await history.save();
    };

    const handleResponse = (res) => {
        const resData = _.get(res, "data", null);
        const resMessageErr = _.get(res, "message", false) || _.get(resData, "Message", false);
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        return resData
    };

    const handleCheckStatusResponse = (data, messageSuccess = "") => {
        const status = _.get(data, "Status", 1);
        if (status === 0) {
            if (_.isEmpty(messageSuccess)) return true;
            Config.notify.show("success", messageSuccess, 2000);
            return true;
        }
        Config.popup.show("ERROR", data?.Message ?? Config.lang("Loi_chua_xac_dinh"));
        return false;
    };

    const handleChange = (key, e) => {
        let value = e?.target?.value ?? e?.value ?? "";
        if (key === "Proportion" || key === "Value") {
            value = e?.value ?? 0;
        }
        setDataForm({...dataForm, [key === "Status" ? "StatusID" : key]: value});
        if (key !== "GoalName") setFieldName(key);
    };

    const loadForm = async () => {
        const {GoalID} = getPropsData;
        setLoadingForm(true);
        const params = {
            GoalID,
            Language: Config.language || "84",
            FormID: "W39F3004",
            Type: type
        };
        const res = await Api.put("/w39f3004/load-form", params);
        setTimeoutLoading(timerFormLoading, setLoadingForm);
        const data = handleResponse(res);
        const isCancel = _.get(data, "IsCancel", 0);
        if (_.isEmpty(isCancel)) loadCboAppStatus(isCancel);
        if (data) {
            setDataForm(data);
            oldData.current = data
        }
    };

    const loadCboAppStatus = async (IsCancel = 0) => {
        const {GoalID} = getPropsData;
        setLoadingCboAppStatus(true);
        const params = {
            GoalID,
            IsCancel,
            FormID: "W39F3002",
            Language: Config.language || "84"
        };
        const res = await Api.put("/w39f3002/get-cbo-status", params);
        const data = handleResponse(res);
        setTimeoutLoading(timerCboAppStatusLoading, setLoadingCboAppStatus);
        if (data) setDataCboAppStatus(data);
    };

    const loadComputeMethod = async () => {
        const res = await Api.put("/w39f3002/get-compute-method", {Language: Config.language || "84"});
        const data = handleResponse(res);
        if (data) setDataCompute(data);
    };

    const onStore = async () => {
        const {GoalID} = getPropsData;
        const params = {
            GoalID,
            FinishDate: getStateData.Status === 1 ? moment().format("YYYY-MM-DD") : null,
            ..._.pickBy(getStateData, (o, k) => !_.startsWith(k, "Is")),
            Proportion: handleParseToIntNumber(getStateData.Proportion),
            Value: handleParseToIntNumber(getStateData.Value),
            Language: Config?.language ?? '84',
            FormID: 'W39F3004',
            GoalPlanID: data?.GoalPlanID ?? '',
            StatusID: dataForm?.StatusID ?? 0,
            GroupGoalID: data?.GroupGoalID ?? '',
        };
        const arrName = ["Value"]
        if(getStateData?.[`IsBalance${getStateData.Type || ""}Proportion`] === 0 ) arrName.push("Proportion"); // if getStateData.Type = "Object" or "key" and IsBalanceObjectProportion or IsBalanceKeyProportion = 0 *validate(add)
        const fieldError = _.reduce(arrName , (acc, name) => {
            acc = {...acc, ...validate(name)}
            return acc;
        }, {});
        setErrors(fieldError);
        if (_.size(fieldError) > 0) return false;

        setLoadingForm(true);
        const resStore = await Api.put("/w39f3004/edit", params);
        setTimeoutLoading(timerFormLoading, setLoadingForm);
        const dataResStore = handleResponse(resStore);
        if (!dataResStore) return false;

        const isSuccessStore = handleCheckStatusResponse(dataResStore, Config.lang("Luu_thanh_cong"));
        if (isSuccessStore) {
            saveHistory();
            const resUpdate = await onUpdateTargetResult(params);
            const dataResUpdate = handleResponse(resUpdate);
            if (!dataResUpdate) return false;
            const isSuccessUpdate = handleCheckStatusResponse(dataResUpdate, Config.lang("Luu_va_cap_nhat_thanh_cong"));
            if (isSuccessUpdate) onClose('W39F3004', false, isSuccessStore);
            return true;
        }
    };

    const onUpdateTargetResult = () => new Promise(resolve => resolve(Api.put("/w39f3004/update-target-result", {
        Result: 0,
        Target: 0,
        Mode: 1,
        Value: handleParseToIntNumber(getStateData.Value),
        ..._.pick(getPropsData, ["ComputeMethod", "Type", "GoalID"])
    })));

    const onDestroyTarget = async () => {
        const {GoalID} = getPropsData;
        const res = await Api.put("/w39f3004/destroy-target", {GoalID});
        const data = handleResponse(res);
        if (!data) return false;
        const isSuccess = handleCheckStatusResponse(data, Config.lang("Huy_muc_tieu_thanh_cong"));
        if (isSuccess) {
            await onUpdateTargetResult();
            saveHistory(true);
            onClose('W39F3004', false, isSuccess);
        }
    };

    const onCloseModal = (...args) => {
        isUpdated.current = _.get(args, "[2]", false);
        setOpenW39F3015(false);
        if (isUpdated.current) loadForm();
    };

    const getComputeMethodName = _.filter(dataCompute, item => _.get(item, "ComputeMethod", "") === getStateData.ComputeMethod)
    const titleButtonCancel = new Map([
        ['KEY','Huy_ket_qua_then_chot'],
        ['OBJECT','Huy_muc_tieu']
    ]);
    return <>
        {isOpenW39F3015 &&
        <W39F3015 handleOpenPopup={onCloseModal} dataCboComputeMethod={dataCompute}
                  goalProps={_.pick(getStateData, ["GoalID", "Type"])} open={isOpenW39F3015}/>}
        <Modal
            moveOutScreen={false}
            onClose={() => {
                onClose('W39F3004', false, isUpdated.current);
            }}
            open={open}
        >
            <ModalHeader>
                {Config.lang("Cap_nhat_thong_tin_nhanh")}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12}>
                        <TextInput
                            disabled={isLoadingForm || !!!getStateData.IsImptEdit}
                            value={getStateData.GoalName}
                            label={Config.lang("Ten")}
                            onChange={(e) => handleChange("GoalName", e)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Dropdown
                            required
                            error={_.get(errors, "StatusID", false)}
                            loading={isLoadingCboAppStatus || isLoadingForm}
                            dataSource={dataCboAppStatus}
                            displayExpr={"StatusName"}
                            valueExpr={"StatusID"}
                            label={Config.lang("Trang_thai")}
                            noDataText={Config.lang("Khong_co_du_lieu")}
                            onChange={e => handleChange("Status", e)}
                            placeholder={Config.lang("Chon")}
                            value={getStateData.Status}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                            <DatePicker
                                onBlur={() => setFieldName("ValidDateFrom")}
                                disabled={isLoadingForm || !!!getStateData.IsAllEdit}
                                required
                                inputProps={{
                                    className: 'Test Input class name'
                                }}
                                placeholder={'DD/MM/YYYY'}
                                displayFormat={'DD/MM/YYYY'}
                                label={Config.lang("Ngay_bat_dau")}
                                max={getStateData.ValidDateTo}
                                onChange={e => handleChange("ValidDateFrom", e)}
                                value={getStateData.ValidDateFrom}
                            />
                    </Col>
                    <Col xs={6}>
                        <DatePicker
                            onBlur={() => setFieldName("ValidDateTo")}
                            disabled={isLoadingForm || !!!getStateData.IsAllEdit}
                            required
                            placeholder={'DD/MM/YYYY'}
                            displayFormat={'DD/MM/YYYY'}
                            label={Config.lang("Ngay_hoan_thanh")}
                            min={getStateData.ValidDateFrom}
                            onChange={e => handleChange("ValidDateTo", e)}
                            value={getStateData.ValidDateTo}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <NumberInput
                            required={getStateData?.[`IsBalance${getStateData.Type || ""}Proportion`] === 0} // if getStateData.Type = "Object" or "key" and IsBalanceObjectProportion or IsBalanceKeyProportion = 0 *required(true),else then = 1(false)
                            label={Config.lang("Trong_so")}
                            error={_.get(errors, "Proportion", false)}
                            disabled={isLoadingForm || !!!getStateData.IsProportionEdit || getStateData?.[`IsBalance${getStateData.Type || ""}Proportion`] === 1} // if getStateData.Type = "Object" or "key" and IsBalanceObjectProportion or IsBalanceKeyProportion = 1 *disabled(true),else then = 0(false)
                            onChange={(e) => handleChange("Proportion", e)}
                            value={getStateData.Proportion}
                            disabledNegative
                            decimalDigit={4}
                            thousandSeparator={','}
                        />
                    </Col>
                    <Col xs={6}>
                        <Button
                            style={{position: "absolute", left: "16%", top: "-5%", zIndex: 10,color: "#0095FF"}}
                            onClick={() => setOpenW39F3015(true)}
                            disabled={!!!getStateData.IsEditMethod}
                            size="small"
                            text={_.get(getComputeMethodName, "[0]ComputeMethodName", "")}
                        />
                        <NumberInput
                            required
                            disabled={
                                isLoadingForm ||
                                (!!!getStateData.IsAllEdit && !!!getStateData.IsEditResult) ||
                                !!!getStateData.IsEditResult ||
                                getStateData.ComputeMethod === "Formula"
                            }
                            error={_.get(errors, "Value", false)}
                            label={Config.lang("Ket_qua")}
                            onChange={e => handleChange("Value", e)}
                            value={getStateData.Value}
                            disabledNegative
                            decimalDigit={4}
                            thousandSeparator={','}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    className={'mgr5'}
                    disabled={isLoadingForm || !!!getStateData.IsCancel}
                    onClick={onDestroyTarget}
                    size="medium"
                    text={Config.lang(titleButtonCancel.get(getStateData.Type?.toUpperCase()) || '')}
                    viewType="outlined"
                />
                <Button
                    color="primary"
                    disabled={_.size(errors) > 0 ||
                    isLoadingForm ||
                    (!!!getStateData.IsAllEdit && !!!getStateData.IsProportionEdit && !!!getStateData.IsImptEdit && !!!getStateData.IsEditMethod)}
                    onClick={onStore}
                    size="medium"
                    startIcon={"save"}
                    text={Config.lang("Luu")}
                    viewType="filled"
                />
            </ModalFooter>
        </Modal>
    </>
}

export default compose(connect(
    null,
    null
), withStyles(styles, {withTheme: true}))(W39F3004);