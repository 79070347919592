/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 7/4/2021
 * @Example
 */

import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
import { NumberInput, TextInput, ButtonIcon, Typography } from "diginet-core-ui/components";
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import Config from "../../../../../config";

const { spacing } = useTheme();

const useStyles = makeStyles(() => ({
    styleBtnAction: { position: "absolute", right: 0, bottom: spacing(-7) },
    noTextSelect: { pointerEvents: "none" },
}));

const W39F3002TextEdit = forwardRef((props, ref) => {
    const classes = useStyles();
    const { value, disabled, unit, type, className, onSave, isRequired, inputProps } = props;
    const { viewType, ...rest } = inputProps;
    const [isEdit, setIsEdit] = useState(false);
    const [errorProportion, setErrorProportion] = useState("");

    const [valueInput, setValueInput] = useState(value);
    const previousValue = useRef(value ?? "");
    const inputRef = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleEdit);
        return () => {
            document.removeEventListener("mousedown", handleEdit);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef]);

    useEffect(() => {
        if (value !== valueInput) {
            previousValue.current = value;
            setValueInput(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    /**
     * Handle focus input
     * Không thực thi khi (không set lại trạng thái Edit - setIsEdit()):
     * - Khi Mousedown vào Button Approval || Cancel (Vì phải chạy hàm onClick của 2 button này nên không thực thi)
     * - Không phải là mousedown Input Khi đang Edit (Typing... nên ko thực thi)
     * @param focus
     */
    const handleEdit = (e, focus = false) => {
        if (
            !["approval", "cancel"].includes(e?.toElement?.parentElement?.id) &&
            ((!focus && inputRef.current?.className !== e?.target?.className) || focus)
        ) {
            setIsEdit(focus);
        }
    };

    useEffect(() => {
        setErrorProportion(isRequired && !valueInput ? Config.lang("Truong_nay_bat_buoc_nhap") : "");
    }, [isRequired, valueInput]);

    const handleSave = useCallback(
        e => {
            if (valueInput !== previousValue && onSave) onSave(valueInput);
            previousValue.current = valueInput;
            handleEdit();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [valueInput]
    );

    const handleCancel = e => {
        setValueInput(previousValue.current);
        handleEdit();
    };

    useImperativeHandle(ref, () => ({
        revert: () => {
            setValueInput(previousValue);
        },
    }));

    const propsCustoms = {
        className,
        readOnly: disabled || !isEdit,
        value: valueInput,
        inputRef: inputRef,
        required: isRequired,
        // custom input theo DS (đặc thù)
        error: errorProportion,
        onChange: e => {
            setValueInput(e.value || e.target.value);
        },
        endIcon: isEdit && (
            <div className={`display_row align-top ${classes.styleBtnAction}`}>
                <Typography color={"info"} className={"mgr4x"} style={{ whiteSpace: "pre" }}>
                    {unit}
                </Typography>
                <ButtonIcon
                    disabled={previousValue.current === valueInput}
                    color={"success"}
                    viewType={"filled"}
                    name={"Approval"}
                    size={"tiny"}
                    id={"approval"}
                    style={{ zIndex: 2 }}
                    className={"mgr2x"}
                    onClick={handleSave}
                />
                <ButtonIcon
                    color={"danger"}
                    viewType={"filled"}
                    name={"Cancel"}
                    size={"tiny"}
                    id={"cancel"}
                    style={{ zIndex: 2 }}
                    onClick={handleCancel}
                />
            </div>
        ),
        viewType: isEdit || !viewType ? "underlined" : viewType,
        ...rest,
    };

    return (
        <div onDoubleClick={e => !disabled && !isEdit && handleEdit(e, true)} className={"full_w"}>
            {type === "number" ? (
                <NumberInput
                    {...propsCustoms}
                    thousandSeparator={","}
                    decimalDigit={4}
                    inputProps={{ className: Config.isMobile && propsCustoms.readOnly && classes.noTextSelect }}
                />
            ) : (
                <TextInput {...propsCustoms} multiline />
            )}
        </div>
    );
});

W39F3002TextEdit.propTypes = {
    value: PropTypes.string,
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    unit: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    thousandSeparator: PropTypes.bool,
    onSave: PropTypes.func,
    isRequired: PropTypes.bool,
    inputProps: PropTypes.object,
};

W39F3002TextEdit.defaultProps = {
    value: "",
    classes: {},
    disabled: false,
    unit: "",
    type: "string",
    className: "",
    thousandSeparator: false,
    onSave: null,
    isRequired: false,
    inputProps: {},
};

export default W39F3002TextEdit;
