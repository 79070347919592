/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 13/4/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Typography, ButtonIcon } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3002Actions from '../../../../../redux/W3X/W39F3002/W39F3002_actions';
import W39F3002Tab1DetailItem from './W39F3002Tab1DetailItem';

const W39F3002Tab1Details = forwardRef((props, ref) => {
    const { classes, goalProps, handleOpenPopup, disabled, mode, isPopup, data } = props;
    const { GoalID } = goalProps;

    const [detailData, setDetailData] = useState([]);
    const [loading, setLoading] = useState(false);
    const listDetailRef = useRef(null);

    const dispatch = useDispatch();

    let position = {
        left: 0,
        x: 0,
    };

    useImperativeHandle(ref, () => ({
        reload: () => {
            loadDetailData();
        },
    }));

    const loadDetailData = () => {
        const param = {
            FormID: 'W39F3002',
            Language: Config.language || '84',
            GoalID: GoalID,
            Mode: 1,
            Type: mode,
        };
        setLoading(true);
        dispatch(
            W39F3002Actions.getDetailData(param, (err, data) => {
                setLoading(false);
                if (err) return Config.popup2.show('ERROR', err);
                if (data) {
                    setDetailData(data);
                }
            })
        );
    };

    useEffect(() => {
        loadDetailData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onMouseDown = (e) => {
        position = {
            left: listDetailRef.current.scrollLeft,
            x: e.clientX,
        };

        if (listDetailRef.current.scrollWidth > listDetailRef.current.clientWidth) {
            listDetailRef.current.addEventListener('mousemove', onMouseMove);
            listDetailRef.current.addEventListener('mouseup', onMouseUp);
        }
    };

    const onMouseMove = (e) => {
        const dx = e.clientX - position.x;

        if (listDetailRef.current) {
            listDetailRef.current.style.cursor = 'grabbing';
            listDetailRef.current.style.userSelect = 'none';
            listDetailRef.current.scrollLeft = position.left - dx;
        }
    };

    const onMouseUp = () => {
        listDetailRef.current.style.cursor = null;
        listDetailRef.current.style.removeProperty('user-select');
        listDetailRef.current.removeEventListener('mousemove', onMouseMove);
        listDetailRef.current.removeEventListener('mouseup', onMouseUp);
    };
    const isDisabledSpanLink = data?.IsBalanceKeyProportion === 1;
    return (
        <>
            <LoadPanel
                shadingColor={"rgba(0,0,0,0.4)"}
                position={{ my: "center", of: "#root" }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <div className={`${classes.tab1DetailCont}`} onMouseDown={onMouseDown} ref={listDetailRef}>
                <div className={`${classes.tab1Detail} ${classes.headerDetail}`}>
                    <div className={`${classes.flexCenter} ${classes.gap10}`}>
                        <ButtonIcon
                            name="Sort"
                            circular
                            viewType={"text"}
                            disabled={!!isPopup}
                            className={"mgr5"}
                            onClick={e => handleOpenPopup("reorderPop", true)}
                        />
                        <Typography type={"p2"} color={"secondary"}>
                            {Config.lang("Ket_qua_then_chot")}
                        </Typography>
                    </div>
                    <div className={`${classes.flexCenter} ${classes.justifyCenter}`}>
                        <Typography type={"p2"} color={"secondary"}>
                            {Config.lang("Ket_qua")}
                        </Typography>
                    </div>
                    <div className={`${classes.flexCenter} ${classes.justifyCenter}`}>
                        <Typography type={"p2"} color={"secondary"} style={{ textAlign: "center" }}>
                            {Config.lang("Trong_so")}
                            <br />
                            <span
                                className={`${classes.spanLink} ${
                                    (disabled || isDisabledSpanLink) ? classes.linkDisabled : classes.pointerCursor
                                }`}
                                onClick={e =>
                                    handleOpenPopup("updateProportion", true)
                                }
                            >
                                {Config.lang("Cap_nhat")}
                            </span>
                        </Typography>
                    </div>
                    <div className={`${classes.flexCenter} ${classes.justifyCenter}`}>
                        <Typography type={"p2"} color={"secondary"}>
                            {Config.lang("Trang_thai")}
                        </Typography>
                    </div>
                </div>
                {detailData?.length > 0 &&
                    detailData.map((i, idx) => {
                        return (
                            <W39F3002Tab1DetailItem
                                key={i?.GoalKeyID || idx}
                                data={i}
                                classes={classes}
                                handleOpenPopup={handleOpenPopup}
                            />
                        );
                    })}
            </div>
        </>
    );
});

W39F3002Tab1Details.propTypes = {
    classes: PropTypes.object,
    goalProps: PropTypes.object,
    handleOpenPopup: PropTypes.func,
    disabled: PropTypes.bool,
    mode: PropTypes.number,
};

W39F3002Tab1Details.defaultProps = {
    classes: {},
    goalProps: {},
    handleOpenPopup: null,
    disabled: false,
    mode: 1,
};

export default W39F3002Tab1Details;
