/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

import { Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import Config from '../../../../config';
import * as W39F3015Actions from '../../../../redux/W3X/W39F3015/W39F3015_actions';
import TabContent, { TabPanel } from '../../../common/tabs/tab-content';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import W39F6000 from '../W39F6000/W39F6000';
import { useStyle } from './style';
import W39F3002Tab1 from './utils/W39F3002Tab1';
import W39F3002Tab2 from './utils/W39F3002Tab2';

const W39F3002 = forwardRef((props, ref) => {
    const { mode, location, goalProps, isShowBackButton } = props;

    const classes = useStyle();

    const [tabIndex, setTabIndex] = useState(0);
    const [isMounted, setIsMounted] = useState(false);

    const W39F3002Tab1Ref = useRef(null);
    const W39F6000Ref = useRef(null);

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        isUpdated: () => {
            return W39F3002Tab1Ref.current?.isUpdated() || W39F6000Ref.current?.isUpdated();
        },
    }));

    const changeTab = (e, index) => {
        setTabIndex(index);
    };

    const getGoalProps = () => {
        let gP = {};
        if (goalProps) {
            gP = goalProps;
        } else {
            const { location } = props;
            if (location) {
                const { state } = location;
                if (state) {
                    gP = state;
                }
            }
        }
        return gP;
    };

    useEffect(() => {
        const { GoalID } = getGoalProps();
        if (!GoalID) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W39F3000',
            });
        } else {
            setIsMounted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { GoalID, Type } = getGoalProps();
    
    const updateProportion = (flag = true, dt) => {
        const params = {
            GoalID: dt?.GoalID ?? GoalID ?? '',
            Type: flag ? (Type ?? '') : null,
            Mode: 1,
        };

        dispatch(
            W39F3015Actions.edit(params, (err) => {
                if (err) {
                    const msg = err.message || Config.lang('Loi_chua_xac_dinh');
                    return Config.popup2.show('ERROR', msg);
                }
            })
        );
    };

    const handleGoBack = () => {
        const fromPath = location?.state?.FromPath ?? '';
        if (fromPath === 'W39F3000') {
            let params = { SelectedGoalPlan: location?.state?.SelectedGoalPlan ?? '' };
            const menu = location?.state?.menu ?? null;
            if (menu) {
                params.menu = menu;
            }
            browserHistory.push({
                pathname: Config.getRootPath() + fromPath,
                state: params,
            });
        } else {
            browserHistory.goBack();
        }
    };

    return isMounted ? (
        <>
            {Type === 'Object' && (
                <ActionToolbar
                    title={Config.lang('Thong_tin_muc_tieu')}
                    onBack={isShowBackButton ? handleGoBack : null}
                    showBorder={true}
                    allwaysTop={false}
                ></ActionToolbar>
            )}
            <Tabs indicatorColor="primary" textColor="primary" value={tabIndex} onChange={changeTab}>
                <Tab key={0} label={Config.lang('Thong_tin_chung')} style={{ textTransform: 'uppercase' }} />
                <Tab key={1} label={Config.lang('Thong_tin_chi_tiet')} style={{ textTransform: 'uppercase' }} />
            </Tabs>
            <Row className={classes.row}>
                <Col xs={12} sm={12} md={9} lg={9} className={classes.col}>
                    <TabContent
                        activeKey={tabIndex}
                        disableSwipe={true}
                        onChangeIndex={changeTab}
                        className={classes.tabContent}
                        style={{ height: '100%', borderRadius: 4, boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}
                    >
                        <TabPanel index={0} style={{ height: '100%' }}>
                            {tabIndex === 0 && (
                                <W39F3002Tab1
                                    isPopup={goalProps}
                                    ref={W39F3002Tab1Ref}
                                    classes={classes}
                                    goalProps={getGoalProps()}
                                    updateProportion={updateProportion}
                                    mode={mode}
                                />
                            )}
                        </TabPanel>
                        <TabPanel index={1}>
                            {tabIndex === 1 && (
                                <W39F3002Tab2 classes={classes} goalProps={getGoalProps()} updateProportion={updateProportion} mode={mode} />
                            )}
                        </TabPanel>
                    </TabContent>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className={classes.col}>
                    <W39F6000 ref={W39F6000Ref} goalProps={getGoalProps()} />
                </Col>
            </Row>
        </>
    ) : (
        <></>
    );
});

W39F3002.propTypes = {
    mode: PropTypes.number,
    isShowBackButton: PropTypes.bool,
};

W39F3002.defaultProps = {
    mode: 1,
    isShowBackButton: true
};

export default W39F3002;
