/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 31/3/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    centerRow: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: 20,
        gap: 10,
        '& > :first-child': {
            flexBasis: 150,
        },
        '& > :not(:first-child)': {
            flex: '1 1 auto',
        }
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    divSelectYear: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    divListSpan: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10,
        margin: '25px 0',
        justifyContent: 'center',
    },
    spanMonth: {
        border: '1px solid #7F828E',
        borderRadius: 4,
        padding: '15px 25px',
        width: 120,
        textAlign: 'center',
        cursor: 'pointer',
    },
    spanQuarter: {
        border: '1px solid #7F828E',
        borderRadius: 4,
        padding: '25px 75px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    spanYear: {
        border: '1px solid #7F828E',
        borderRadius: 4,
        padding: '25px 45px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    selected: {
        background: '#EFF5FF',
    },
    divPeriodCont: {
        flex: 'unset !important',
        position: 'relative',
    },
    divPeriod: {
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        border: '1px solid',
        borderRadius: 4,
        gap: 10,
        cursor: 'pointer',
    },
    typoPeriod: {
        cursor: 'pointer',
        padding: '5px 0',
        '&:hover': {
            background: '#EFF5FF',
        }
    }
}))