/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/31/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import { compose, bindActionCreators }      from "redux";
import { connect }                          from "react-redux";
import Modal                                from "../../../common/modal/modal";
import Config                               from "../../../../config";
import {Combo, MForm}                       from "../../../common/form-material";
import * as W39F3001Actions                 from "../../../../redux/W3X/W39F3001/W39F3001_actions";
import * as generalActions                  from "../../../../redux/general/general_actions";
import _                                                                                    from "lodash";
import {
    Button,
    TextInput,
    Radio,
    Dropdown,
    Attachment,
    TreeView,
    Checkbox,
    Tooltip,
    Col,
    Row,
    DatePicker,
    FormGroup,
    NumberInput,
} from 'diginet-core-ui/components';
import History                                                                              from "../../../libs/history";
import CDN                                                                                  from "../../../CDN";
import {browserHistory}                                                                     from "react-router";
import moment                                                                               from "moment";
import PropTypes              from "prop-types";
import {makeStyles} from "diginet-core-ui/theme";
import {Column}               from "devextreme-react/data-grid";
import Icon from "diginet-core-ui/icons";
const useStyles = makeStyles(() => ({
    cboEmployee: {
        '& .MuiFormControl-marginDense': {
            marginTop:0
        }
    }
}));
const W39F3001 = (props) => {

    const initDataForm = {
        GoalID: "",
        GoalName: "",
        OrgChartID: "",
        GoalPlanID: null,
        EmployeeID: "",
        GroupGoalID: null,
        ValidDateFrom: null,
        ValidDateTo: null,
        Proportion: "",
        IsBalanceObjectProportion: 0,
        MeasureMethod: "%",
        Description: "",
        StatisticalCode: "",
        ConcurrentID: "",
        ComputeMethod: "",
    };

    const initFilterCboEmployees = {
        skip: 0,
        limit: 20,
        strSearch: ""
    };

    const initDataCboEmployees = {
        rows: [],
        total: 0
    };

    const nameColumnCboConcurrent = [
        {
            "FieldName": "DivisionName",
            "Caption": Config.lang("Don_vi"),
        },
        {
            "FieldName": "DepartmentName",
            "Caption": Config.lang("Phong_ban"),
        },
        {
            "FieldName": "TeamName",
            "Caption": Config.lang("To_nhom"),
        },
        {
            "FieldName": "DutyName",
            "Caption": Config.lang("Chuc_vu"),
        },
        {
            "FieldName": "WorkName",
            "Caption": Config.lang("Cong_viec"),
        }
    ];


    const { open, onClose, getGroupTargets, iPermission, tabIndex, selectedGoalPlan} = props;
    const [errorHelperText, setErrorHelperText] = useState({});
    const [dataForm, setDataForm] = useState(initDataForm);
    const [dataCboEmployees, setDataCboEmployees] = useState(initDataCboEmployees);
    const [dataOrgChart, setDataOrgChart] = useState([]);
    const [dataTableTarget, setDataTableTarget] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataRedirectScreen, setDataRedirectScreen] = useState({});
    const [goalMode, setGoalMode] = useState(0);
    const [isSaved, setIsSaved] = useState(false);
    const [isGoalName, setIsGoalName] = useState(true);
    const [dataCboGoalName, setDataCboGoalName] = useState([]);
    const [dataRefCaption, setDataRefCaption] = useState({});
    const [dataCaptions, setDataCaptions] = useState([]);
    const [dataFieldCoeffObjectives, setDataFieldCoeffObjectives] = useState({});
    const [dataCoeffObjectives, setDataCoeffObjectives] = useState({});
    const [isConcurrent, setIsConcurrent] = useState(false);
    const [dataCboConcurrent, setDataCboConcurrent] = useState( []);
    const [isCheckEmployeeConcurrent, setIsCheckEmployeeConcurrent] = useState(false);
    let dataFilter = useRef(initFilterCboEmployees);
    let attachments = useRef([]);
    let deletedFile = useRef([]);
    let dropDownTreeView = useRef(null);
    let cboEmployees = useRef(null);
    let setDefaultEmployee = useRef(false);
    let checkDataOrgChartID = useRef(false);
    let checkOnContinue = useRef(false);
    let isSetCboEmployee = useRef(false);
    let dataDefaultEmployee = useRef(initDataCboEmployees);
    let  isChecked = useRef(false);
    let  columnCBo = useRef(nameColumnCboConcurrent);
    const classes = useStyles();

    const loadTableTarget = () => {
        setLoading(true);
        props.w39f3001Actions.getTableTarget((error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataTableTarget(data);
                const dataSelectedGoalPlan = data.find(item => item.GoalPlanID  === selectedGoalPlan);
                const dataDefault = !_.isEmpty(dataSelectedGoalPlan) ?
                    dataSelectedGoalPlan : ((data.length > 0 && data[0]) || {});
                setData({
                    GoalPlanID:dataDefault?.GoalPlanID || "",
                    ValidDateFrom:dataDefault?.DateFrom || null,
                    ValidDateTo: dataDefault?.DateTo || null,
                    IsBalanceObjectProportion: dataDefault?.IsBalanceObjectProportion || 0
                })
            }
        });
    };

    const loadGroupTarget = (goalPlanID) => {
        const params = {
            GoalPlanID: goalPlanID
        };
        setLoading(true);
        props.w39f3001Actions.getGroupTarget(params,(error) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        });
    };

    const loadCboConcurrent = () => {
        const params = {
            FormID: "W39F3001",
            Language: Config.language || 84,
            EmployeeID: _.get(dataForm, "EmployeeID.EmployeeID", ""),
            OrgChartID: _.get(dataForm, "OrgChartID", ""),
            GoalPlanID: _.get(dataForm, "GoalPlanID", ""),
            GoalMode: tabIndex ?? goalMode,
            TransID: "",
            Mode: 2,
        };
        setLoading(true);
        props.w39f3001Actions.getCboConcurrent(params,(error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            setDataCboConcurrent(data || []);
        });
    };

    const loadCheckEmployeeConcurrent = () => {
        const param = {
            EmployeeID: _.get(dataForm, "EmployeeID.EmployeeID", "")
        };
        props.w39f3001Actions.getCheckEmployeeConcurrent(param, (error, data) => {
            if (error) return Config.popup.show('ERROR', error);
            setIsCheckEmployeeConcurrent(!!data?.Status)
        })
    };

    const loadDataOrgChart = () => {
        setLoading(true);
        props.generalActions.getOrgCharts({}, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataOrgChart(data || []);
        });
    };

    const loadCboEmployees = (isReset, search = "") => {
        const params = {
            HostID: "",
            FormID: "W39F3001",
            Type: "EmployeeID",
            Language: Config.language || "84",
            skip: dataFilter.current.skip,
            limit: dataFilter.current.limit,
            search: dataFilter.current.strSearch,
            search2:  search || (goalMode && !checkDataOrgChartID.current ? dataForm?.OrgChartID : "") || ""
        };
        setLoading(true);
        props.w39f3001Actions.getCboEmployees(params, (error,data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            const rows = data?.rows ? data.rows : data;
            const total = data?.total ? data.total : rows.length;
            setDataCboEmployees({
                rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                total: total
            });
            // set data default combo Employee
            if(!isSetCboEmployee.current) {
                isSetCboEmployee.current = true;
                dataDefaultEmployee.current = { rows: rows, total: total};
            }

        });
    };

    const loadCboGoalName = () => {
        let params = {
            FormID: "W39F3001",
            SearchValue: "",
            skip: 0,
            limit: 20,
            ComputeMethod: "",
            GoalPlanID: dataForm?.GoalPlanID || "",
        };
        if(isGoalName) {
            params.GoalMode = goalMode;
            params.ObjectID = goalMode === 0 ? (dataForm.EmployeeID?.EmployeeID || dataForm.EmployeeID) : dataForm.OrgChartID;
        }
        setLoading(true);
        props.w39f3001Actions.getCboGoalName(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            setDataCboGoalName(data || []);
        });
    };

    const loadCaptions = () => {
        setLoading(true);
        props.w39f3001Actions.getCaptions((error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            const dataVisible = _.filter(data,["IsUsed", 1]);
            setDataRefCaption(_.reduce(dataVisible, (acc, currItem) => {
                acc = {...acc, [currItem.RefID]: ""}
                return acc;
            }, {}));
            setDataCaptions(dataVisible || []);
        });
    };

    const loadCoeffObjectives = () => {
        const params = {
            FormID: 'W39F3001',
            GoalID: dataForm?.GoalID ?? "",
            Mode: 3,
            Type: 1,
        };
        setLoading(true);
        props.w39f3001Actions.getCoeffObjectives(params,(error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            const dataVisible = _.filter(data,["IsUsed", 1]);
            setDataCoeffObjectives(_.reduce(dataVisible, (acc, currItem) => {
                acc = {...acc, [currItem.CoeffID]: ""};
                return acc;
            }, {}));
            setDataFieldCoeffObjectives(dataVisible || []);
        });
    };



    useEffect(() => {
        loadTableTarget();
        loadCboEmployees();
        loadDataOrgChart();
        loadCaptions();
        setGoalMode(tabIndex);
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        Object.keys(dataForm).forEach(key => {
            if (dataForm[key]) {
                errorHelperText[key] = "";
                setErrorHelperText(errorHelperText)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm]);

    useEffect(() => {
        if(_.get(dataForm, 'GoalPlanID', "")) {
            loadGroupTarget(_.get(dataForm, 'GoalPlanID', ""));
            loadCboConcurrent();
            setData({ConcurrentID: ""});
            if(isGoalName) {
                loadCboGoalName();
                isChecked.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.GoalPlanID]);

    useEffect(() => {
        if(_.get(dataForm, "EmployeeID.EmployeeID", "")) {
            loadCboConcurrent();
            loadCheckEmployeeConcurrent();
            setData({ConcurrentID: ""});
            if(!goalMode && isGoalName) loadCboGoalName();
        } else {
            setIsCheckEmployeeConcurrent(false);
            setIsConcurrent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.EmployeeID?.EmployeeID]);

    useEffect(() => {
        if(!goalMode) {
            if(_.get(dataForm, 'OrgChartID', "")) {
                loadCboEmployees();
                loadCboConcurrent();
                setData({OrgChartID: "", ConcurrentID: ""});
            }
        } else {
            loadCboConcurrent();
            setData({ConcurrentID: ""});
        }
        if(isGoalName) loadCboGoalName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goalMode]);

    useEffect(() => {
        if(isConcurrent) {
            setData({ConcurrentID : dataCboConcurrent?.[0] ?? ""});
        } else {
            setData({ConcurrentID : ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConcurrent]);

    useEffect(() => {
        if(dataCboEmployees?.rows && dataCboEmployees.rows.length > 0) {
            if(!setDefaultEmployee.current) {
                setDefaultEmployee.current = true;
                setData({EmployeeID: dataCboEmployees.rows[0] || ""});
            }
        }
        if(_.get(dataForm, "EmployeeID.EmployeeID", "")) {
            if(dataCboEmployees?.rows && dataCboEmployees.rows.length > 0) {
                const employee  = dataCboEmployees.rows.find(item => item.EmployeeID === _.get(dataForm, "EmployeeID.EmployeeID", ""));
                if(!employee) {
                    setData({EmployeeID: ""});
                }
            } else {
                setData({EmployeeID: ""});
            }
        }
        if(_.get(dataForm, 'OrgChartID', "")) {
            if(dataCboEmployees?.rows && dataCboEmployees.rows.length > 0) {
                const employee  = dataCboEmployees.rows.find(item => item.EmployeeID === _.get(dataForm, "EmployeeID.EmployeeID", ""));
                setData({EmployeeID: employee ? employee : ""});
            } else {
                setData({EmployeeID: ""});
            }
        }
        // set data EmployeeID when click on continue
        if(checkOnContinue.current) {
            if(dataDefaultEmployee.current?.rows && dataDefaultEmployee.current?.rows.length > 0) {
                setData({EmployeeID: dataDefaultEmployee.current?.rows[0] || ""})
            }
            checkOnContinue.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboEmployees, dataForm?.OrgChartID]);

    useEffect(()=>{
        if (isGoalName && goalMode) loadCboGoalName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataForm?.OrgChartID]);

    useEffect(() => {
        if(isChecked.current && isGoalName) {
            loadCboGoalName();
        }
        if(!isGoalName) {
            setData({GoalName: "", GoalID: "", StatisticalCode: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGoalName]);

    useEffect(() => {
        if(dataCboGoalName && dataForm?.GoalID) {
            const dataGoalID = dataCboGoalName.find(item => item.GoalID === dataForm?.GoalID);
            setData({
                Description: dataGoalID?.Description,
                GroupGoalID: dataGoalID?.GroupGoalID,
                MeasureMethod: dataGoalID?.MeasureMethod,
                ComputeMethod: dataGoalID?.ComputeMethod,
                StatisticalCode: `${dataGoalID?.GoalID ?? ""} - ${dataGoalID?.GoalName || ""}`
            });
        }
        loadCoeffObjectives();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.GoalID]);


    const onClosePopup = (isReload = false) => {
        if(onClose) onClose(isReload);
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [ ...e.removedAttached];
        }
    };

    const _getAttachments = (file) => {
        const dataFile = _.get(file, 'data.paths', []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const resetForm = () => {
        setErrorHelperText({});
        setDataOldAttachments([]);
        setDataForm(initDataForm);
        setGoalMode(0);
        setLoading(false);
        setIsSaved(false);
        attachments.current = [];
        deletedFile.current = [];
        dropDownTreeView.current = null;
        cboEmployees.current = null;
        setDefaultEmployee.current = false;
        checkDataOrgChartID.current = false;
        isSetCboEmployee.current = false;
        dataFilter.current = initFilterCboEmployees;
        setIsCheckEmployeeConcurrent(false);
        setIsConcurrent(false);
    };

    const setDataLoadEmployee = () => {
        checkOnContinue.current = true;
        checkDataOrgChartID.current = true;
        loadCboEmployees();
    };

    const onContinue = async () => {
        const currentTableTarget = dataForm?.GoalPlanID ? dataTableTarget.find(i => i?.GoalPlanID === dataForm?.GoalPlanID) : null;
        setDataLoadEmployee();
        loadCheckEmployeeConcurrent();
        setErrorHelperText({});
        setDataOldAttachments([]);
        setIsConcurrent(false);
        setLoading(false);
        setIsSaved(false);
        attachments.current = [];
        deletedFile.current = [];
        dropDownTreeView.current = null;
        cboEmployees.current = null;
        setDefaultEmployee.current = true;
        checkDataOrgChartID.current = false;
        dataFilter.current = initFilterCboEmployees;
        isChecked.current = false;
        setIsGoalName(true);
        setData({
            ValidDateFrom: currentTableTarget?.DateFrom || null,
            ValidDateTo: currentTableTarget?.DateTo || null,
            GoalID: "",
            GoalName: "",
            OrgChartID: "",
            GroupGoalID: null,
            Proportion: "",
            MeasureMethod: "%",
            Description: "",
            StatisticalCode: "",
            ComputeMethod: "",
        });
        setDataRefCaption(_.mapValues(dataRefCaption, ""));
    };


    const handleChange = (key,e) => {
        if (!key) return false;
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        if (_.startsWith(key, "StrObjectiveRef")) setDataRefCaption({...dataRefCaption, [key]: value});
        if (_.startsWith(key, "CoeffObjective")) setDataCoeffObjectives({...dataCoeffObjectives, [key]: value});


        if (key === 'OrgChartID'){
            if(value === "") {
                checkDataOrgChartID.current = true;
            }
            loadCboEmployees(true, value);
        }

        if (key === 'EmployeeID'){
            if (goalMode){
                const employee = Config.getUser({ EmployeeID: _.get(value, 'EmployeeID', "") });
                if (employee?.OrgChartID !== dataForm?.OrgChartID) {
                    let message = Config.lang("Vui_long_chon_nhan_vien_thuoc_co_cau_to_chuc_da_chon_o_tren");
                    Config.popup.show('INFO', message);
                    if (cboEmployees.current) {
                        cboEmployees.current.instance.option("value", "");
                    }
                    return false;
                }
            }
        }

        if(key==="GoalPlanID") {
            setData({[key]: value, IsBalanceObjectProportion: e.data.IsBalanceObjectProportion})
            return
        }

        setData({[key]: value});
    };

    const _removeCDN = () => {
        if (deletedFile.current && deletedFile.current.length > 0) {
            deletedFile.current.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            deletedFile.current= [];
        }
    };

    const onReloadSave = async (params) => {
        setLoading(true);
        props.w39f3001Actions.save(params, async (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                if(data.Status === 1 && data.MsgAsk === 0) {
                    Config.popup.show('INFO', data.Message);
                    return false;
                }
                _removeCDN();
                setDataRedirectScreen({GoalID:data.GoalID});
                await saveHistory({...params,...data});
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                setIsSaved(true);
            }
        });
    }

    const onSave = async () => {
        const validateRules = [
            {
                name: "GoalPlanID",
                rules: ["isRequired"],
                value: dataForm.GoalPlanID || ""
            },
            {
                name: "EmployeeID",
                rules: ["isRequired"],
                value: _.get(dataForm, "EmployeeID.EmployeeID", "")
            },
            {
                name: "GroupGoalID",
                rules: ["isRequired"],
                value: dataForm.GroupGoalID || ""
            },
            {
                name: "ValidDateFrom",
                rules: ["isRequired"],
                value: dataForm.ValidDateFrom || ""
            },
            {
                name: "ValidDateTo",
                rules: ["isRequired"],
                value: dataForm.ValidDateTo || ""
            },
            {
                name: "Proportion",
                rules: ["isRequired"],
                value: dataForm.Proportion || dataForm.Proportion === 0 ? dataForm.Proportion : ""
            }
        ];
        if(!isGoalName) {
            validateRules.push({
                name: "GoalName",
                rules: ["isRequired"],
                value: dataForm.GoalName || ""
            },)
        } else {
            validateRules.push( {
                name: "GoalID",
                rules: ["isRequired"],
                value: dataForm.GoalID || ""
            },)
        }
        if(goalMode) {
            validateRules.push({
                name: "OrgChartID",
                rules: ["isRequired"],
                value: dataForm.OrgChartID || ""
            })
        }
        if(dataForm.IsBalanceObjectProportion) {
            const indexOfProportion = validateRules.findIndex(v=> v.name === "Proportion")
            validateRules.splice(indexOfProportion, 1)
        }

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setErrorHelperText(validateForm);
            if(setErrorHelperText(validateForm)) return false;
        } else {
            const dataResUploadFile = await _uploadFile(attachments.current, true);
            const validDateFrom = _.get(dataForm, "ValidDateFrom", null);
            const validDateTo = _.get(dataForm, "ValidDateTo", null);
            const arrAttachments = _getAttachments(dataResUploadFile);
            const dataGoalID = dataCboGoalName.find(item => item.GoalID === dataForm?.GoalID);


            let params = {
                Mode: goalMode,
                EmployeeID: _.get(dataForm, "EmployeeID.EmployeeID", ""),
                Language: Config.language || 84,
                GoalPlanID: _.get(dataForm, "GoalPlanID", ""),
                OrgChartID: goalMode ? _.get(dataForm, "OrgChartID", "") : "",
                GoalName: isGoalName ? dataGoalID?.GoalName : _.get(dataForm, "GoalName", ""),
                GroupGoalID: _.get(dataForm, "GroupGoalID", ""),
                Proportion: _.get(dataForm, "Proportion", 0) || 0,
                ValidDateFrom: validDateFrom ? moment(validDateFrom).format("YYYY-MM-DD") : null,
                ValidDateTo: validDateTo ? moment(validDateTo).format("YYYY-MM-DD") : null,
                MeasureMethod: _.get(dataForm, "MeasureMethod", "%"),
                Description: _.get(dataForm, "Description", ""),
                ShareType: "",
                IsGoalName: +isGoalName,
                GoalMode: goalMode,
                GoalID : dataForm?.GoalID || "",
                IsConcurrent: +isConcurrent,
                ConcurrentID: _.get(dataForm, "ConcurrentID.ConcurrentID", ""),
                VoucherID: _.get(dataForm, "ConcurrentID.VoucherID", "") ?? "" ,
                ComputeMethod: isGoalName ? _.get(dataForm, "ComputeMethod", "") : "Manual",
                arrAttachment: JSON.stringify(arrAttachments),
                IsBalanceObjectProportion: dataForm.IsBalanceObjectProportion,
                ...dataRefCaption,
            };


            if(Object.keys(dataCoeffObjectives).length > 0) {
                const _dataCoeffObjectives = {};
                Object.keys(dataCoeffObjectives).forEach((k) => {
                    _dataCoeffObjectives[k] = dataCoeffObjectives[k] ? dataCoeffObjectives[k] : 0
                });
                params = {
                    ...params,
                    ..._dataCoeffObjectives,
                }
            }


            if(!_.isEmpty(dataForm.MeasureMethod)){
                onReloadSave(params)
            }else{
                Config.popup.show("YES_NO", Config.lang("Don_vi_tinh_chua_duoc_nhap.Ban_co_muon_luu_khong"),() => {
                    onReloadSave(params)
                });
            }
        }
    };

    const saveHistory = async (data) => {
        if(!data) return false;

        const employee = Config.getUser({EmployeeID: _.get(data, 'EmployeeID', "")}) ;
        const goalPlanID = dataTableTarget.find(item => item.GoalPlanID === data.GoalPlanID);
        const groupGoalID = getGroupTargets.find(item => item.GroupGoalID === data.GroupGoalID);
        const orgChartID = dataOrgChart.find(item => item.OrgChartID === dataForm.OrgChartID);
        const GoalID = _.get(data, "GoalID", "");
        const refCaptionName = _.reduce(dataCaptions, (acc,currItem) => {
            acc = {...acc, [currItem.RefID]: currItem.Name };
            return acc;
        }, {});
        const dtFieldCoeffObjectives = _.reduce(dataFieldCoeffObjectives, (acc,currItem) => {
            acc = {...acc, [currItem.CoeffID]: currItem.Name };
            return acc;
        }, {});

        const dtFormatCoeffObjectives = {};
        Object.keys(dataCoeffObjectives).forEach(item => {
            dtFormatCoeffObjectives[item] = new Intl.NumberFormat().format(dataCoeffObjectives[item] ?? 0);
        });

        if (GoalID) {
            // data Content
            const _data = {
                GoalName: _.get(data, "GoalName", ""),
                GoalPlanName: _.get(goalPlanID, "GoalPlanName", ""),
                OrgChartName: _.get(orgChartID, "OrgName", ""),
                EmployeeName: _.get(employee, "EmployeeName", ""),
                GroupGoalName: _.get(groupGoalID, "GroupGoalName", ""),
                Type: _.get(data, "Mode", 0) ? Config.lang("Muc_tieu_co_cau_to_chuc") : Config.lang("Muc_tieu_nhan_vien"),
                IsConcurrent: isConcurrent ? Config.lang("Co") : Config.lang("Khong"),
                ...dataRefCaption,
                ...dtFormatCoeffObjectives,
            };

            //name Content
            const captions = {
                GoalName: "Ten_muc_tieu",
                GoalPlanName: "Bang_muc_tieu",
                OrgChartName: "Co_cau_to_chuc",
                EmployeeName: "Nhan_vien",
                GroupGoalName: "Nhom_muc_tieu",
                Type: "Loai_muc_tieu",
                IsConcurrent: "Muc_tieu_kiem_nhiem",
                ...refCaptionName,
                ...dtFieldCoeffObjectives,
            };

            const options = {
                data: _data,
                captions: captions,
                action: 0,
                ModuleID: "D39",
                TransID: GoalID,
                TransactionID: "W39F3001",
                TransactionName: "Thêm mục tiêu",
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const redirectScreen = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F3002",
            state: {GoalID: _.get(dataRedirectScreen, "GoalID", "")},
        });
    };
    const errorGoalID = !!errorHelperText['GoalID'] && errorHelperText['GoalID'].length > 0;
    const errorGoalName = !!errorHelperText['GoalName'] && errorHelperText['GoalName'].length > 0;
    const errorGoalPlanID = !!errorHelperText['GoalPlanID'] && errorHelperText['GoalPlanID'].length > 0;
    const errorEmployeeID = !!errorHelperText['EmployeeID'] && errorHelperText['EmployeeID'].length > 0;
    const errorGroupGoalID = !!errorHelperText['GroupGoalID'] && errorHelperText['GroupGoalID'].length > 0;
    const errorOrgChartID = !!errorHelperText['OrgChartID'] && errorHelperText['OrgChartID'].length > 0;
    const errorValidDateFrom = !!errorHelperText['ValidDateFrom'] && errorHelperText['ValidDateFrom'].length > 0;
    const errorValidDateTo = !!errorHelperText['ValidDateTo'] && errorHelperText['ValidDateTo'].length > 0;
    const errorProportion = !!errorHelperText['Proportion'] && errorHelperText['Proportion'].length > 0;
    return (
        <>
            <Modal open={open} maxWidth={"md"} fullWidth={true}
                   onClose={() => onClosePopup(true)}
                   title={Config.lang("Them_muc_tieu")}
            >
                <Modal.Content>
                    <div className={""}>
                        <FormGroup style={{marginBottom: 5}}>
                            <Row>
                                <Col xs={6} sm={6} md={3} lg={3}>
                                    <Radio
                                        label={Config.lang("Muc_tieu_ca_nhan")}
                                        value={String(goalMode)}
                                        checked={goalMode === 0}
                                        onChange={() => setGoalMode(0)}
                                    />
                                </Col>
                                <Col xs={6} sm={6} md={3} lg={3}>
                                    <Radio
                                        label={Config.lang("Muc_tieu_co_cau_to_chuc")}
                                        value={String(goalMode)}
                                        disabled={iPermission <= 2}
                                        checked={goalMode === 1}
                                        onChange={() => setGoalMode(1)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    error={errorGoalPlanID ? errorHelperText['GoalPlanID'] : ""}
                                    dataSource={dataTableTarget}
                                    displayExpr={"GoalPlanName"}
                                    valueExpr={"GoalPlanID"}
                                    label={Config.lang("Bang_muc_tieu")}
                                    required={true}
                                    disabled={loading}
                                    value={dataForm.GoalPlanID}
                                    clearAble={true}
                                    onChange={e => handleChange("GoalPlanID",e)}
                                />
                            </Col>
                                <Col xs={12} sm={12}>
                                    <Checkbox
                                        className="mgb4x"
                                        label={Config.lang("Chon_tu_danh_muc_muc_tieu")}
                                        checked={isGoalName}
                                        onChange={e => setIsGoalName(e.target.checked)}
                                    />
                                    {isGoalName
                                        ?
                                        <Dropdown
                                            error={errorGoalID ? errorHelperText['GoalID'] : ""}
                                            dataSource={dataCboGoalName}
                                            displayExpr={"GoalName"}
                                            valueExpr={"GoalID"}
                                            label={Config.lang("Ten_muc_tieu")}
                                            clearAble={true}
                                            required={true}
                                            allowSearch={true}
                                            value={dataForm.GoalID}
                                            onChange={e => handleChange("GoalID",e)}
                                            multilineSelectedItem
                                        />
                                        :
                                        <TextInput
                                            error={errorGoalName ? errorHelperText['GoalName'] : ""}
                                            label={Config.lang("Ten_muc_tieu")}
                                            required={true}
                                            disabled={loading}
                                            value={dataForm.GoalName}
                                            onChange={e => handleChange("GoalName",e)}
                                            multiline
                                        />
                                    }
                                </Col>
                                <Col xs={12}>
                                    <TextInput
                                        label={Config.lang("Ma_thong_ke")}
                                        value={isGoalName ? dataForm?.StatisticalCode  : ""}
                                        readOnly={true}
                                    />
                                </Col>

                            {!!goalMode &&
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        ref={dropDownTreeView}
                                        dataSource={dataOrgChart}
                                        required={Boolean(goalMode)}
                                        label={Config.lang('Co_cau_to_chuc')}
                                        value={dataForm.OrgChartID}
                                        clearAble={true}
                                        name="OrgChartID"
                                        displayExpr="OrgName"
                                        valueExpr="OrgChartID"
                                        onChange={(e) => {
                                            handleChange("OrgChartID",e);
                                        }}
                                        error={errorOrgChartID ? errorHelperText['OrgChartID'] : ""}
                                    >
                                        <TreeView
                                            dataSource={dataOrgChart}
                                            displayExpr="OrgName"
                                            valueExpr="OrgChartID"
                                            expand={true}
                                            allowSearch
                                            value={dataForm.OrgChartID}
                                            id="OrgChartID"
                                            parentID="OrgChartParentID"
                                            onChange={(e) => {
                                                handleChange("OrgChartID",e);
                                                dropDownTreeView.current.closeDropdown();
                                            }}
                                        />
                                    </Dropdown>
                                </Col>
                            }

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo.LoadMore
                                        reference={ref => cboEmployees.current = ref}
                                        error={errorEmployeeID && errorHelperText['EmployeeID']}
                                        dataSource={dataCboEmployees}
                                        skip={dataFilter.current.skip}
                                        limit={dataFilter.current.limit}
                                        displayExpr={"EmployeeName"}
                                        keyExpr={'EmployeeID'}
                                        valueExpr={'EmployeeID'}
                                        value={dataForm.EmployeeID}
                                        required={true}
                                        className={classes.cboEmployee}
                                        disabled={loading}
                                        shrink={true}
                                        stylingMode={"underlined"}
                                        label={Config.lang('Nguoi_thuc_hien')}
                                        itemRender={(e) => {
                                            const {data} = e;
                                            if (!data) return null;
                                            return data.EmployeeID + ' - ' + data.EmployeeName;
                                        }}
                                        showClearButton={true}
                                        onValueChanged={(e) => handleChange("EmployeeID",e)}
                                        onInputChanged={(e) => {
                                            dataFilter.current.strSearch = e.target.value;
                                            dataFilter.current.skip = 0;
                                            loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            dataFilter.current.skip = e.skip;
                                            dataFilter.current.limit = e.limit;
                                            loadCboEmployees();
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        error={errorGroupGoalID ? errorHelperText['GroupGoalID'] : ""}
                                        dataSource={getGroupTargets}
                                        displayExpr={"GroupGoalName"}
                                        valueExpr={"GroupGoalID"}
                                        label={Config.lang("Nhom_muc_tieu")}
                                        required={true}
                                        clearAble={true}
                                        readOnly={loading || isGoalName}
                                        value={dataForm.GroupGoalID}
                                        onChange={e => handleChange("GroupGoalID",e)}
                                    />
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className={"display_row align-center"}>
                                        <DatePicker
                                            error={errorValidDateFrom && errorHelperText['ValidDateFrom']}
                                            max={dataForm.ValidDateTo}
                                            onChange={e => handleChange("ValidDateFrom", e)}
                                            required={true}
                                            disabled={loading}
                                            viewType={"underlined"}
                                            label={Config.lang("Ngay_bat_dau")}
                                            clearAble
                                            className={"full_w"}
                                            placeholder={"DD/MM/YYYY"}
                                            value={dataForm.ValidDateFrom || ""}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className={"display_row align-center"}>
                                        <DatePicker
                                            error={errorValidDateTo && errorHelperText['ValidDateTo']}
                                            min={dataForm.ValidDateFrom}
                                            onChange={e => handleChange("ValidDateTo", e)}
                                            required={true}
                                            viewType={"underlined"}
                                            label={Config.lang("Ngay_hoan_thanh")}
                                            clearAble
                                            className={"full_w"}
                                            placeholder={"DD/MM/YYYY"}
                                            value={dataForm.ValidDateTo || ""}
                                        />
                                    </div>
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <NumberInput
                                        error={errorProportion ? errorHelperText['Proportion'] : ""}
                                        required={!dataForm.IsBalanceObjectProportion}
                                        disabled={loading || !!dataForm.IsBalanceObjectProportion}
                                        value={dataForm.Proportion}
                                        label={Config.lang("Trong_so")}
                                        onChange={e => handleChange("Proportion",e)}
                                        decimalDigit={4}
                                        decimalSymbol="."
                                        disabledNegative
                                        delayOnChange={800}
                                        thousandSeparator
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <TextInput
                                        label={Config.lang("Don_vi_tinh")}
                                        disabled={loading}
                                        value={dataForm.MeasureMethod}
                                        onChange={e => handleChange("MeasureMethod",e)}
                                    />
                                </Col>

                            { isCheckEmployeeConcurrent ?
                                <>

                                        <Col xs={12} sm={12}>
                                            <Checkbox
                                                className={"mgb15"}
                                                label={Config.lang("Tao_muc_tieu_cho_chuc_danh_kiem_nhiem")}
                                                checked={isConcurrent}
                                                onChange={e => setIsConcurrent(e.target.checked)}
                                            />
                                        </Col>

                                    {isConcurrent ?
                                        <>

                                                <Col xs={12} sm={12}>
                                                    <div className={"display_row align-center"}>
                                                        <label className={"mgr15"}>
                                                            {Config.lang("Thong_tin_kiem_nhiem")}
                                                        </label>
                                                        <Tooltip
                                                            title={Config.lang("Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen")}
                                                        >
                                                            <Icon name={'info'}/>
                                                        </Tooltip>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <Combo.LoadMore
                                                        dataSource={dataCboConcurrent}
                                                        totalItems={dataCboConcurrent.length}
                                                        keyExpr={"ConcurrentID"}
                                                        valueExpr={"ConcurrentID"}
                                                        loading={loading}
                                                        acceptCustomValue={false}
                                                        value={dataForm.ConcurrentID}
                                                        shrink={true}
                                                        displayExpr={(data) => {
                                                            return data?.DepartmentName + " - " + data?.DutyName;
                                                        }}
                                                        className={"mgb15"}
                                                        showColumnHeaders={true}
                                                        stylingMode={'underlined'}
                                                        showClearButton={true}
                                                        onValueChanged={e => handleChange("ConcurrentID", e)}
                                                    >
                                                        {columnCBo?.current?.map((field, idxField) => {
                                                            return (
                                                                <Column key={idxField}
                                                                        dataField={field.FieldName}
                                                                        caption={field.Caption}
                                                                />
                                                            )
                                                        })}
                                                    </Combo.LoadMore>
                                                </Col>
                                        </>
                                        : ""}
                                </>
                                : ""}


                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <TextInput
                                        multiline
                                        label={Config.lang("Mo_ta")}
                                        disabled={loading}
                                        value={dataForm.Description}
                                        onChange={e => handleChange("Description",e)}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    {_.map(dataCaptions,(item, i) => {
                                        return <TextInput
                                            key={i}
                                            multiline
                                            className={"mgb4x"}
                                            disabled={loading}
                                            label={Config.lang(item.Name)}
                                            value={_.get(dataRefCaption, item.RefID, "")}
                                            onChange={e => handleChange(item.RefID, e)}
                                        />
                                    })}
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                    {_.map(dataFieldCoeffObjectives,(item, i) => {
                                        return <NumberInput
                                            key={i}
                                            label={item.Name}
                                            disabled={loading}
                                            disabledNegative={true}
                                            decimalDigit={4}
                                            decimalSymbol="."
                                            thousandSeparator
                                            delayOnChange={800}
                                            value={_.get(dataCoeffObjectives, item.CoeffID, 0)}
                                            onChange={e => handleChange(item.CoeffID, e)}
                                        />
                                    })}
                                </Col>
                            </Row>
                        <FormGroup>
                            <Attachment
                                label={Config.lang("Dinh_kem")}
                                data={dataOldAttachments}
                                readOnly={loading}
                                onChange={(e) => {
                                    onChangeAttachments(e)
                                }}
                            />
                        </FormGroup>
                    </div>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        {isSaved &&
                        <Button
                            text={Config.lang("Xem_chi_tiet_muc_tieu_vua_tao")}
                            viewType={"text"}
                            color={"info"}
                            style={{textDecoration: "underline", background: "#fff !important"}}
                            disabled={loading}
                            onClick={redirectScreen}
                        />
                        }
                    </div>
                    <div>
                        {!isSaved &&
                        <Button
                            text={Config.lang("Luu")}
                            color={"info"}
                            startIcon={"save"}
                            viewType={"filled"}
                            disabled={loading}
                            size={"medium"}
                            onClick={onSave}
                        />
                        }
                        {isSaved &&
                        <Button
                            text={Config.lang("Nhap_tiep")}
                            color={"info"}
                            viewType={"filled"}
                            disabled={loading}
                            size={"medium"}
                            onClick={onContinue}
                        />
                        }
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    );
};

W39F3001.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default compose(
    connect(state => ({
            getCboEmployees: state.general.getCboEmployees,
            getGroupTargets: state.W39F3001.getGroupTargets,
            detailData: state.W39F3000.detailData
        }),
        (dispatch) => ({
            w39f3001Actions: bindActionCreators(W39F3001Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W39F3001);
