/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

import {
    Attachment,
    Dropdown,
    LinearProgress,
    PopupV2,
    Typography,
    ModalBody,
    ModalHeader,
    Modal,
    Row,
    Col,
    Icon,
    DateRangePicker,
    Button,
} from "diginet-core-ui/components";
import moment from "moment";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from "../../../../../config";
import * as W39F3002Actions from "../../../../../redux/W3X/W39F3002/W39F3002_actions";
import CDN from "../../../../CDN";
import { Loading } from "../../../../common/loading/loading";
import History from "../../../../libs/history";
import W39F3004 from "../../W39F3004/W39F3004";
import W39F3015 from "../../W39F3015/W39F3015";
import W39F3016 from "../../W39F3016/W39F3016";
import W39F3002 from "../W39F3002";
import W39F3002AddTR from "./W39F3002AddTR";
import W39F3002ReorderItem from "./W39F3002ReorderItem";
import W39F3002Tab1Details from "./W39F3002Tab1Details";
import W39F3002TextEdit from "./W39F3002TextEdit";
import W39F3002UpdateProportion from "./W39F3002UpdateProportion";

const W39F3002Tab1 = forwardRef((props, ref) => {
    const { classes, goalProps, updateProportion, mode, isPopup } = props;
    const { GoalID } = goalProps;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [dataCboStatus, setDataCboStatus] = useState([]);
    const [dataCboComputeMethod, setDataCboComputeMethod] = useState([]);
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
    const [dataPopup, setDataPopup] = useState(() => {
        return {
            title: "",
            key: "",
        };
    });
    const [oldData, setOldData] = useState({});
    const [dataTR, setDataTR] = useState({ GoalID: "", Type: "" });
    const [isOpenW39F3002Pop, setIsOpenW39F3002Pop] = useState(false);
    const [isOpenW39F3015Pop, setIsOpenW39F3015Pop] = useState(false);
    const [isOpenW39F3016Pop, setIsOpenW39F3016Pop] = useState(false);
    const [isOpenReorderPop, setIsOpenReorderPop] = useState(false);
    const [isOpenUpdateProporsionPop, setIsOpenUpdateProporsionPop] = useState(false);
    const [goalData, setGoalData] = useState(() => {
        return {
            ...goalProps,
            IsAllEdit: data?.IsAllEdit ?? 0,
            IsEditResult: data?.IsEditResult ?? 0,
        };
    });
    const [attachments, setAttachments] = useState([]);
    const [isOpenAddTRPop, setIsOpenAddTRPop] = useState(false);
    const [isOpenW39F3004Pop, setIsOpenW39F3004Pop] = useState(false);

    const goalNameRef = useRef(null);
    const goalDesRef = useRef(null);
    const goalStatusRef = useRef(null);
    const tab1DetailsRef = useRef(null);
    const W39F3002PopRef = useRef(null);
    const isUpdated = useRef(false);

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        isUpdated: () => {
            return isUpdated.current;
        },
    }));

    const loadData = () => {
        const param = {
            FormID: "W39F3002",
            Language: Config.language ?? "84",
            GoalID: GoalID,
            Mode: 0,
            Type: mode,
        };
        dispatch(
            W39F3002Actions.getData(param, (err, dt) => {
                if (err) return Config.popup.show("ERROR", err);
                if (dt && Object.keys(dt).length > 0) {
                    setData(dt);
                    loadCboStatus(dt);
                    loadAttachments(dt);
                }
            })
        );
    };

    const loadCboStatus = dt => {
        const param = {
            FormID: "W39F3002",
            Language: Config.language ?? "84",
            GoalID: GoalID,
            IsCancel: dt?.IsCancel ?? 0,
        };
        dispatch(
            W39F3002Actions.getComboStatus(param, (err, dt1) => {
                if (err) return Config.popup.show("ERROR", err);
                if (dt1) {
                    setDataCboStatus(dt1);
                }
            })
        );
    };

    const loadCboComputeMethod = () => {
        const param = {
            Language: Config.language ?? "84",
        };
        dispatch(
            W39F3002Actions.getComboComputeMethod(param, (err, data) => {
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    setDataCboComputeMethod(data);
                }
            })
        );
    };

    const loadAttachments = dt => {
        const param = {
            KeyID: dt?.GoalObjectID ?? "",
            TableName: "D39T2045",
        };

        dispatch(
            W39F3002Actions.getAttachments(param, (err, dt1) => {
                if (err) return Config.popup.show("ERROR", err);
                if (dt1) {
                    setAttachments(dt1);
                }
            })
        );
    };

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        Promise.all([loadData(), loadCboComputeMethod()]).then(() => {
            if (isMounted) {
                setLoading(false);
            }
        });
        return () => (isMounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getComputeMethodName = () => {
        const computeMethod = data.ComputeMethod || "";
        const computeMethodObj = dataCboComputeMethod.find(i => i.ComputeMethod === computeMethod);
        return computeMethodObj ? computeMethodObj.ComputeMethodName : "";
    };

    const handleChangeValue = (key, value) => {
        let goalObjectID = data?.GoalObjectID ?? "";
        let action = 0;
        let moduleID = "";
        let transactionID = "";
        let transactionName = "";
        let param = {};
        switch (key) {
            case "GoalObjectName":
                param = {
                    GoalObjectID: goalObjectID,
                    GoalObjectName: value ?? "",
                };

                dispatch(
                    W39F3002Actions.updateGoalName(param, (err, dt) => {
                        if (err) {
                            if (goalNameRef.current) goalNameRef.current.revert();
                            return Config.popup.show("ERROR", err);
                        }
                        if (dt.Status === 0) {
                            const dataH = {
                                Ten_muc_tieu: value ?? "",
                            };
                            const oldDataH = {
                                Ten_muc_tieu: data?.GoalObjectName ?? "",
                            };
                            const captions = {
                                Ten_muc_tieu: "Ten_muc_tieu",
                            };
                            action = 1;
                            moduleID = "D39";
                            transactionID = "W39F3002";
                            transactionName = "Thông tin mục tiêu";
                            saveHistory(
                                goalObjectID,
                                dataH,
                                oldDataH,
                                captions,
                                action,
                                moduleID,
                                transactionID,
                                transactionName
                            );
                            changeIsUpdated();
                            setData({ ...data, [key]: value ?? "" });
                            Config.notify.show("success", Config.lang("Cap_nhat_ten_muc_tieu_thanh_cong"), 2000);
                        } else if (dt.Status === 1) {
                            if (goalNameRef.current) goalNameRef.current.revert();
                            Config.notify.show("error", Config.lang("Cap_nhat_ten_muc_tieu_that_bai"), 2000);
                        }
                    })
                );
                break;
            case "Description":
                param = {
                    GoalObjectID: goalObjectID,
                    Description: value ?? "",
                };

                dispatch(
                    W39F3002Actions.updateGoalDes(param, (err, dt) => {
                        if (err) {
                            if (goalDesRef.current) goalDesRef.current.revert();
                            return Config.popup.show("ERROR", err);
                        }
                        if (dt.Status === 0) {
                            changeIsUpdated();
                            setData({ ...data, [key]: value ?? "" });
                            Config.notify.show("success", Config.lang("Cap_nhat_mo_ta_muc_tieu_thanh_cong"), 2000);
                        } else if (dt.Status === 1) {
                            if (goalDesRef.current) goalDesRef.current.revert();
                            Config.notify.show("error", Config.lang("Cap_nhat_mo_ta_muc_tieu_that_bai"), 2000);
                        }
                    })
                );
                break;
            case "StatusObjectID":
                setDataPopup({
                    title: Config.lang("Ban_co_muon_cap_nhat_trang_thai_muc_tieu"),
                    key: key,
                    value: value ?? 0,
                });
                setOpenConfirmPopup(true);
                break;
            case "ValidDate":
                const startDate = Config.convertDate(value[0], null, "YYYY-MM-DD[T00:00:00.000Z]");
                const endDate = Config.convertDate(value[1], null, "YYYY-MM-DD[T00:00:00.000Z]");
                if (startDate !== data["ValidDateFrom"] || endDate !== data["ValidDateTo"]) {
                    setOldData({ ...oldData, ValidDateFrom: data["ValidDateFrom"], ValidDateTo: data["ValidDateTo"] });
                    setData({
                        ...data,
                        ValidDateFrom: startDate,
                        ValidDateTo: endDate,
                    });
                    setDataPopup({
                        title: Config.lang("Ban_co_muon_cap_nhat_han_hoan_thanh"),
                        key: "ValidDate",
                        value: [startDate, endDate],
                    });
                    setOpenConfirmPopup(true);
                }
                break;
            default:
                break;
        }
    };

    const checkStatus = () => {
        const { value } = dataPopup;

        const params = {
            FormID: "W39F3002",
            GoalPlanID: data?.GoalPlanID ?? "",
            GoalID: GoalID ?? "",
            StatusObjectID: value ?? 0,
        };
        dispatch(
            W39F3002Actions.checkStatus(params, (err, dt) => {
                if (err) return Config.popup.show("ERROR", err);
                if (dt?.Status === 0) {
                    const params = {
                        GoalObjectID: data?.GoalObjectID ?? "",
                        StatusID: value ?? 0,
                    };

                    if (value === 1) {
                        params.FinishDate = moment().format("YYYY-MM-DD");
                    }

                    dispatch(
                        W39F3002Actions.updateGoalStatus(params, async (err, dt) => {
                            if (err) {
                                return Config.popup.show("ERROR", err);
                            }
                            if (dt.Status === 0) {
                                const dataH = {
                                    GoalObjectName: `${data?.GoalObjectID ?? ""}-${data?.GoalObjectName ?? ""}`,
                                    StatusID: value ?? 0,
                                };
                                const oldDataH = {
                                    GoalObjectName: `${data?.GoalObjectID ?? ""}-${data?.GoalObjectName ?? ""}`,
                                    StatusID: data?.StatusObjectID ?? 0,
                                };
                                const captions = {
                                    GoalObjectName: "Ten_muc_tieu",
                                    StatusID: "Trang_thai",
                                };

                                await saveHistory(
                                    data?.GoalObjectID ?? 0,
                                    dataH,
                                    oldDataH,
                                    captions,
                                    1,
                                    "D39",
                                    "W39F3002",
                                    "Thông tin mục tiêu"
                                );
                                setLoading(true);
                                Promise.all([
                                    updateProportion(),
                                    loadData(),
                                    tab1DetailsRef.current && tab1DetailsRef.current.reload(),
                                ]).then(() => {
                                    setLoading(false);
                                });
                                changeIsUpdated();
                                Config.notify.show(
                                    "success",
                                    Config.lang("Cap_nhat_trang_thai_muc_tieu_thanh_cong"),
                                    2000
                                );
                            } else if (dt.Status === 1) {
                                Config.notify.show("error", Config.lang("Cap_nhat_trang_thai_muc_tieu_that_bai"), 2000);
                            }
                        })
                    );
                } else if (dt?.Status === 1 && dt?.MsgAsk === 0) {
                    return Config.popup.show("ERROR", dt?.Message, () => {});
                }
            })
        );
    };

    const handleConfirmPopup = () => {
        const { key, value } = dataPopup;
        switch (key) {
            case "StatusObjectID":
                checkStatus();
                break;
            case "ValidDate":
                const param = {
                    GoalObjectID: data?.GoalObjectID ?? "",
                    ValidDateFrom: value[0],
                    ValidDateTo: value[1],
                };
                dispatch(
                    W39F3002Actions.updateGoalDeadline(param, (err, dt) => {
                        if (err) {
                            setData({
                                ...data,
                                ValidDateFrom: oldData["ValidDateFrom"],
                                ValidDateTo: oldData["ValidDateTo"],
                            });
                            return Config.popup.show("ERROR", err);
                        }
                        if (dt.Status === 0) {
                            changeIsUpdated();
                            Config.notify.show(
                                "success",
                                Config.lang("Cap_nhat_han_hoan_thanh_muc_tieu_thanh_cong"),
                                2000
                            );
                        } else if (dt.Status === 1) {
                            setData({
                                ...data,
                                ValidDateFrom: oldData["ValidDateFrom"],
                                ValidDateTo: oldData["ValidDateTo"],
                            });
                            Config.notify.show("error", Config.lang("Cap_nhat_han_hoan_thanh_muc_tieu_that_bai"), 2000);
                        }
                    })
                );
                break;
            default:
                break;
        }
        setOpenConfirmPopup(false);
    };

    const handleCancelPopup = () => {
        const { key } = dataPopup;
        switch (key) {
            case "ValidDate":
                setData({ ...data, ValidDateFrom: oldData["ValidDateFrom"], ValidDateTo: oldData["ValidDateTo"] });
                break;
            default:
                break;
        }
        setOpenConfirmPopup(false);
    };

    const saveHistory = async (
        transID,
        data,
        oldData = {},
        captions,
        action,
        moduleID,
        transactionID,
        transactionName
    ) => {
        if (!data || !captions || !action || !moduleID || !transactionID || !transactionName) return false;
        if (transID) {
            const options = {
                data: data,
                captions: captions,
                action: action,
                ModuleID: moduleID,
                TransID: transID,
                TransactionID: transactionID,
                TransactionName: transactionName,
            };

            if (oldData) {
                options.dataCompare = oldData;
            }

            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const handleOpenPopup = (name, flag = false, dt) => {
        switch (name) {
            case "W39F3002":
                if (flag) {
                    setDataTR(dt);
                }
                if (!flag && W39F3002PopRef.current?.isUpdated()) {
                    setLoading(true);
                    Promise.all([loadData(), tab1DetailsRef.current && tab1DetailsRef.current.reload()]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenW39F3002Pop(flag);
                break;
            case "W39F3015":
                if (flag && !dt?.IsEditMethod) return;
                if (flag && dt) {
                    setGoalData(dt);
                }
                if (!flag && dt) {
                    changeIsUpdated();
                    setLoading(true);
                    Promise.all([loadData(), tab1DetailsRef.current && tab1DetailsRef.current.reload()]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenW39F3015Pop(flag);
                break;
            case "W39F3016":
                if (flag && (!dt?.IsAllEdit || !dt?.IsEditResult)) return;
                if (flag && dt) {
                    setGoalData(dt);
                }
                if (!flag && dt) {
                    changeIsUpdated();
                    setLoading(true);
                    Promise.all([loadData(), tab1DetailsRef.current && tab1DetailsRef.current.reload()]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenW39F3016Pop(flag);
                break;
            case "reorderPop":
                if (!flag) {
                    setLoading(true);
                    Promise.all([tab1DetailsRef.current && tab1DetailsRef.current.reload()]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenReorderPop(flag);
                break;
            case "updateProportion":
                if (flag && !data?.IsAllEdit) return;
                if (!flag && dt) {
                    setLoading(true);
                    Promise.all([
                        updateProportion(false, dt),
                        loadData(),
                        tab1DetailsRef.current && tab1DetailsRef.current.reload(),
                    ]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenUpdateProporsionPop(flag);
                break;
            case "addTR":
                if (flag && !data?.IsAllEdit) return;
                if (flag && dt) {
                    setGoalData(dt);
                }
                if (!flag && dt) {
                    setLoading(true);
                    Promise.all([
                        updateProportion(false, dt),
                        loadData(),
                        tab1DetailsRef.current && tab1DetailsRef.current.reload(),
                    ]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenAddTRPop(flag);
                break;
            case "W39F3004":
                if (flag && !dt?.IsAllEdit) return;
                if (flag && dt) {
                    dt = { ...dt, GoalPlanID: data?.GoalPlanID ?? "" };
                    setGoalData(dt);
                }
                if (!flag && dt) {
                    setLoading(true);
                    Promise.all([loadData(), tab1DetailsRef.current && tab1DetailsRef.current.reload()]).then(() => {
                        setLoading(false);
                    });
                }
                setIsOpenW39F3004Pop(flag);
                break;
            default:
                break;
        }
    };

    const handleChangeAttachments = async e => {
        if (e?.removedAttached?.length > 0) {
            const listRemoveAttachedID = e.removedAttached.map(i => i.AttachmentID);
            let isSuccessRemoved = true;

            await Promise.all([
                e.removedAttached.forEach(i => {
                    const param = {
                        VoucherID: data?.GoalObjectID ?? "",
                        TableName: "D39T2045",
                        AttachmentID: i?.AttachmentID ?? "",
                    };

                    dispatch(
                        W39F3002Actions.removeAttachment(param, (err, dt) => {
                            if (err) {
                                isSuccessRemoved = false;
                                return Config.popup.show("ERROR", err);
                            }
                            if (dt === "Deleted") {
                                changeIsUpdated();
                                return Config.notify.show("success", Config.lang("Xoa_dinh_kem_thanh_cong"), 2000);
                            } else {
                                isSuccessRemoved = false;
                                return Config.notify.show("error", Config.lang("Xoa_dinh_kem_that_bai"), 2000);
                            }
                        })
                    );
                }),
            ]);

            if (isSuccessRemoved) {
                const newAttachments = [...attachments].filter(
                    attachment => listRemoveAttachedID.indexOf(attachment.AttachmentID) === -1
                );
                setAttachments(newAttachments);
            }
        } else if (Object.keys(e?.allNewAttached ?? {}).length > 0) {
            let listAttachment = [];

            await Promise.all(
                Object.keys(e?.allNewAttached).map(async i => {
                    return await uploadFile([e?.allNewAttached[i]]);
                })
            ).then(attachs => {
                listAttachment = attachs.map(i => {
                    const splitName = i?.fileName?.split(".");
                    return {
                        URL: i?.filePath ?? "",
                        FileName: i?.fileName ?? "",
                        FileSize: i?.fileSize ?? 0,
                        FileExt: splitName?.[splitName.length - 1] ?? "",
                        Description: "",
                        DescriptionU: "",
                    };
                });
            });

            const param = {
                VoucherID: data?.GoalObjectID ?? "",
                TableName: "D39T2045",
                DivisionID: Config.getDivisionID(),
                arrAttachment: JSON.stringify(listAttachment),
            };

            dispatch(
                W39F3002Actions.addAttachments(param, (err, dt) => {
                    if (err) {
                        return Config.popup.show("ERROR", err);
                    }
                    if (dt?.length === listAttachment.length) {
                        changeIsUpdated();
                        dt.forEach((i, idx) => {
                            listAttachment[idx].AttachmentID = i.AttachmentID ?? "";
                            listAttachment[idx].CreateUserID = Config?.profile?.UserID ?? "";
                            listAttachment[idx].UserName = Config?.profile?.UserNameU ?? "";
                            listAttachment[idx].CreateDate = new Date().toISOString() ?? "";
                        });
                        setAttachments([...attachments, ...listAttachment]);
                        return Config.notify.show("success", Config.lang("Them_dinh_kem_thanh_cong"), 2000);
                    } else {
                        return Config.notify.show("error", Config.lang("Them_dinh_kem_that_bai"), 2000);
                    }
                })
            );
        }
    };

    const uploadFile = async file => {
        return await new Promise((rs, rj) => {
            CDN.uploadFile(file, null, (err, dt) => {
                if (err) {
                    Config.popup.show("ERROR", err);
                    rj(err);
                }
                rs(dt?.paths?.[0]);
            });
        });
    };

    const changeIsUpdated = () => {
        isUpdated.current = true;
    };

    return (
        <div className={classes.container}>
            <Loading visible={loading} />
            <Row>
                <Col xs={12} className={"display_row align-center"}>
                    <Icon name={"Locate"} className={"mgr1x"} />
                    <W39F3002TextEdit
                        ref={goalNameRef}
                        value={data.GoalObjectName}
                        disabled={!data.IsImptEdit}
                        onSave={e => handleChangeValue("GoalObjectName", e)}
                        inputProps={{ viewType: "none", inputStyle: { fontSize: 18, fontWeight: "bold" } }}
                    />
                </Col>
                <Col xs={12}>
                    <W39F3002TextEdit
                        ref={goalDesRef}
                        value={data.Description || ""}
                        disabled={!data.IsAllEdit}
                        onSave={e => handleChangeValue("Description", e)}
                        inputProps={{ label: Config.lang("Mo_ta") }}
                    />
                </Col>
                <Col xs={12} md={8}>
                    <DateRangePicker
                        controls
                        clearAble
                        fullWidth
                        showDateDisplay={false}
                        disabled={!data?.IsAllEdit}
                        value={[data.ValidDateFrom, data.ValidDateTo]}
                        label={Config.lang("Han_hoan_thanh")}
                        placeholder={Config.lang("Han_hoan_thanh")}
                        onChange={e => handleChangeValue("ValidDate", e.value)}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Dropdown
                        label={Config.lang("Trang_thai")}
                        ref={ref => (goalStatusRef.current = ref)}
                        dataSource={dataCboStatus}
                        displayExpr={"StatusName"}
                        noDataText={Config.lang("Khong_co_du_lieu")}
                        onChange={e => handleChangeValue("StatusObjectID", e ? e.value : "")}
                        valueExpr={"StatusID"}
                        value={data?.StatusObjectID ?? 0}
                        disabled={!data?.IsImptEdit}
                    />
                </Col>
                <Col xs={12} md={8}>
                    <Typography type={"p2"} color={"dark8"}>
                        {Config.lang("Tien_do_thuc_hien")}
                        &nbsp;
                        <span
                            className={`${classes.spanLink} ${
                                data?.IsEditMethod ? classes.pointerCursor : classes.linkDisabled
                            }`}
                            onClick={e =>
                                handleOpenPopup("W39F3015", true, {
                                    ...goalProps,
                                    IsEditMethod: data?.IsEditMethod ?? 0,
                                    IsEditResult: data?.IsEditResult ?? 0,
                                })
                            }
                        >
                            {getComputeMethodName()}
                        </span>
                    </Typography>
                </Col>
                <Col xs={12} md={4}>
                    <Typography type={"p2"} color={"dark8"}>
                        <span
                            className={`${classes.spanLink} ${
                                data?.IsAllEdit && data?.IsEditResult && data?.ComputeMethod !== "Formula"
                                    ? classes.pointerCursor
                                    : classes.linkDisabled
                            }`}
                            onClick={e => {
                                if (data?.ComputeMethod === "Formula") return;
                                handleOpenPopup("W39F3016", true, {
                                    ...goalProps,
                                    IsAllEdit: data?.IsAllEdit ?? 0,
                                    IsEditMethod: data?.IsEditMethod ?? 0,
                                    IsEditResult: data?.IsEditResult ?? 0,
                                });
                            }}
                        >
                            {Config.lang("Cap_nhat")}
                        </span>
                        &nbsp;|&nbsp;
                        {data?.ObjectResult || 0}%
                    </Typography>
                </Col>
                <Col xs={12} className={"mgb5x"}>
                    <LinearProgress
                        color={"#FFAA00"}
                        background={"#D4D5D9"}
                        duration={1}
                        determinate
                        height={8}
                        percent={data?.ObjectResult || 0}
                        showValue
                        style={{ marginTop: 35 }}
                    />
                </Col>
                {data?.Type === "Object" && (
                    <Col xs={12}>
                        <W39F3002Tab1Details
                            data={data}
                            isPopup={isPopup}
                            ref={tab1DetailsRef}
                            classes={classes}
                            goalProps={goalProps}
                            handleOpenPopup={handleOpenPopup}
                            disabled={!data?.IsAllEdit ?? false}
                            mode={mode}
                        />
                    </Col>
                )}
                {data?.Type === "Object" && (
                    <Col xs={12} className={"display_row valign-middle"}>
                        <Button
                            startIcon={"Plus"}
                            viewType={"text"}
                            onClick={e =>
                                handleOpenPopup("addTR", true, {
                                    GoalObjectID: data?.GoalObjectID ?? "",
                                    GoalPlanID: data?.GoalPlanID ?? "",
                                    EmployeeID: data?.EmployeeID ?? "",
                                    IsBalanceKeyProportion: data?.IsBalanceKeyProportion || 0,
                                })
                            }
                            label={Config.lang("Them_ket_qua_then_chot")}
                        />
                    </Col>
                )}
                <Col xs={12}>
                <Attachment
                    label={Config.lang("Dinh_kem")}
                    hintText={Config.lang("Keo_va_tha_tep_tin_vao_day")}
                    deleteNotifyText={Config.lang("Ban_co_chac_muon_xoa_(nhung)_dinh_kem_nay?")}
                    disabled={!data?.IsAllEdit}
                    data={attachments}
                    maxFile={5}
                    maxSize={"25mb"}
                    multiple
                    onChange={e => handleChangeAttachments(e)}
                    ownerName={Config.lang("Chu_so_huu")}
                    unitSize={"mb"}
                    uploadErrorInfo={{
                        existingFile: Config.lang("File_da_duoc_dinh_kem"),
                        maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                        maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                    }}
                />
                </Col>
            </Row>
            {openConfirmPopup && (
                <PopupV2
                    open={openConfirmPopup}
                    fullScreen
                    onCancel={() => handleCancelPopup()}
                    onClose={() => setOpenConfirmPopup(false)}
                    onConfirm={() => handleConfirmPopup()}
                    pressESCToClose
                    subtitle={dataPopup.title}
                    description={""}
                />
            )}
            {data?.Type === "Object" && isOpenW39F3002Pop && (
                <Modal fullWidth maxWidth={"xl"} open={isOpenW39F3002Pop} onClose={e => handleOpenPopup("W39F3002")}>
                    <ModalHeader title={Config.lang("Thong_tin_ket_qua_then_chot")} />
                    <ModalBody>
                        <W39F3002 ref={W39F3002PopRef} goalProps={dataTR} key={dataTR?.GoalID} />
                    </ModalBody>
                </Modal>
            )}
            {isOpenReorderPop && (
                <W39F3002ReorderItem
                    open={isOpenReorderPop}
                    handleOpenPopup={handleOpenPopup}
                    GoalObjectID={data?.GoalObjectID ?? ""}
                    GoalObjectName={data?.GoalObjectName ?? ""}
                    classes={classes}
                />
            )}
            {isOpenUpdateProporsionPop && (
                <W39F3002UpdateProportion
                    open={isOpenUpdateProporsionPop}
                    handleOpenPopup={handleOpenPopup}
                    GoalObjectID={data?.GoalObjectID ?? ""}
                    GoalObjectName={data?.GoalObjectName ?? ""}
                    EmployeeID={data?.EmployeeID ?? ""}
                    GoalPlanID={data?.GoalPlanID ?? ""}
                    GoalID={GoalID}
                    classes={classes}
                />
            )}
            {isOpenW39F3015Pop && (
                <W39F3015
                    open={isOpenW39F3015Pop}
                    handleOpenPopup={handleOpenPopup}
                    dataCboComputeMethod={dataCboComputeMethod}
                    goalProps={{ ...goalData, Type: goalData.Type || data.Type }}
                />
            )}
            {isOpenW39F3016Pop && (
                <W39F3016 open={isOpenW39F3016Pop} handleOpenPopup={handleOpenPopup} goalProps={goalData} />
            )}
            {isOpenAddTRPop && (
                <W39F3002AddTR
                    classes={classes}
                    open={isOpenAddTRPop}
                    handleOpenPopup={handleOpenPopup}
                    goalProps={goalData}
                    masterProps={goalProps}
                />
            )}
            {isOpenW39F3004Pop && (
                <W39F3004 open={isOpenW39F3004Pop} data={goalData} onClose={handleOpenPopup} type={mode} />
            )}
        </div>
    );
});

W39F3002Tab1.propTypes = {
    classes: PropTypes.object,
    goalProps: PropTypes.object,
    updateProportion: PropTypes.func,
    mode: PropTypes.number,
};

W39F3002Tab1.defaultProps = {
    classes: {},
    goalProps: { GoalID: "", Type: "" },
    updateProportion: null,
    mode: 1,
};

export default W39F3002Tab1;
