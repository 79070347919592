/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

// import { LoadPanel } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import {
    ButtonIcon,
    Checkbox,
    Dropdown,
    Tooltip,
    Typography,
    Col,
    Row,
    Label,
    DatePicker,
} from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../../config";
import * as W39F3002Actions from "../../../../../redux/W3X/W39F3002/W39F3002_actions";
import Api from "../../../../../services/api";
import { Combo, MForm } from "../../../../common/form-material";
import { Loading } from "../../../../common/loading/loading";
import History from "../../../../libs/history";
import W39F3015 from "../../W39F3015/W39F3015";
import W39F3002TextEdit from "./W39F3002TextEdit";

W39F3002Tab2.propTypes = {
    classes: PropTypes.object,
    goalProps: PropTypes.object,
    updateProportion: PropTypes.func,
    mode: PropTypes.number,
};

W39F3002Tab2.defaultProps = {
    classes: {},
    goalProps: { GoalID: "", Type: "" },
    updateProportion: null,
    mode: 1,
};

function W39F3002Tab2(props) {
    const dataDynamicRef = useSelector(state => state.W39F3002?.dataDynamicRef ?? []);
    const nameColumnCboConcurrent = [
        {
            FieldName: "DivisionName",
            Caption: Config.lang("Don_vi"),
        },
        {
            FieldName: "DepartmentName",
            Caption: Config.lang("Phong_ban"),
        },
        {
            FieldName: "TeamName",
            Caption: Config.lang("To_nhom"),
        },
        {
            FieldName: "DutyName",
            Caption: Config.lang("Chuc_vu"),
        },
        {
            FieldName: "WorkName",
            Caption: Config.lang("Cong_viec"),
        },
    ];

    const { classes, goalProps, updateProportion, mode } = props;

    const { GoalID, Type } = goalProps;

    const [data, setData] = useState({});
    const [dataCboComputeMethod, setDataCboComputeMethod] = useState([]);
    const [dataCboGroupTarget, setDataCboGroupTarget] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpenW39F3015Pop, setIsOpenW39F3015Pop] = useState(false);
    const [dataCboConcurrent, setDataCboConcurrent] = useState([]);
    const [isCheckEmployeeConcurrent, setIsCheckEmployeeConcurrent] = useState(false);
    const [error, setError] = useState({});
    const [isConcurrent, setIsConcurrent] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [coeffObjectives, setCoeffObjectives] = useState([]);

    const proportionRef = useRef(null);
    const groupTargetRef = useRef(null);
    const originData = useRef(null);
    const originCoeffObjectives = useRef(null);
    const StrObjectRefs = useRef([]);
    const columnCBo = useRef(nameColumnCboConcurrent);
    const isSetConcurrentID = useRef(false);
    const dataOld = useRef({});
    const CoeffObjectiveRefs = useRef([]);

    const dispatch = useDispatch();

    const loadData = () => {
        const param = {
            FormID: "W39F3002",
            Language: Config.language ?? "84",
            GoalID: GoalID,
            Mode: 2,
            Type: mode,
        };
        dispatch(
            W39F3002Actions.getData(param, (err, dt) => {
                if (err) return Config.popup.show("ERROR", err);
                if (dt) {
                    originData.current = dt?.master ?? {};
                    originCoeffObjectives.current = dt?.detail ?? [];
                    setData(dt?.master ?? {});
                    setIsConcurrent(!!dt?.master?.IsConcurrent);
                    dataOld.current = {
                        ...dataOld.current,
                        ...dt?.master,
                        GoalName: `${dt?.master?.GoalID || ""} - ${dt?.master?.GoalName || ""}`,
                        IsConcurrent: dt?.master?.IsConcurrent ? Config.lang("Co") : Config.lang("Khong"),
                    };
                    const coeffObjs = dt?.detail
                        ?.filter(item => item.IsUsed);
                    setCoeffObjectives(coeffObjs);
                    loadCboGroupTarget(dt?.master);
                    loadCboConcurrent(dt?.master);
                    loadCheckEmployeeConcurrent(dt?.master);
                }
            })
        );
    };

    const loadCboConcurrent = dt => {
        const params = {
            FormID: "W39F3002",
            Language: Config.language || 84,
            EmployeeID: dt?.EmployeeID ?? "",
            OrgChartID: "",
            GoalPlanID: dt?.GoalPlanID ?? "",
            GoalMode: dt?.GoalMode ?? "",
            TransID: "",
            Mode: 2,
        };
        dispatch(
            W39F3002Actions.getCboConcurrent(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                setDataCboConcurrent(data || []);
            })
        );
    };

    const loadCheckEmployeeConcurrent = dt => {
        const param = {
            EmployeeID: dt?.EmployeeID || "",
        };
        dispatch(
            W39F3002Actions.getCheckEmployeeConcurrent(param, (err, data) => {
                if (err) return Config.popup.show("ERROR", err);
                setIsCheckEmployeeConcurrent(!!data?.Status);
            })
        );
    };

    const onUpdate = () => {
        const concurrentID = isConcurrent && data?.Type === "Object" ? data?.ConcurrentID?.ConcurrentID || "" : "";
        const voucherID = isConcurrent && data?.Type === "Object" ? data?.ConcurrentID?.VoucherID || "" : "";
        const validateRules = [];
        if (isConcurrent) {
            validateRules.push({
                name: "ConcurrentID",
                rules: ["isRequired"],
                value: data?.ConcurrentID?.ConcurrentID ?? "",
            });
        }
        const validateForm = MForm.formValidation(validateRules);

        if (Object.keys(validateForm).length > 0) {
            setError(validateForm);
            return;
        }
        const params = {
            FormID: "W39F3002",
            GoalID: data?.GoalID || "",
            TransID: data?.TransID || "",
            IsConcurrent: +isConcurrent ?? 0,
            ConcurrentID: concurrentID,
            VoucherID: voucherID,
        };
        dispatch(
            W39F3002Actions.update(params, async error => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else {
                    isSetConcurrentID.current = false;
                    setIsChanged(false);
                    await saveHistory2(params);
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    loadData();
                }
            })
        );
    };

    const loadCboComputeMethod = () => {
        const param = {
            Language: Config.language ?? "84",
        };
        dispatch(
            W39F3002Actions.getComboComputeMethod(param, (err, data) => {
                if (err) return Config.popup.show("ERROR", err);
                if (data) {
                    setDataCboComputeMethod(data);
                }
            })
        );
    };

    const loadCboGroupTarget = async dt => {
        const param = {
            GoalPlanID: dt?.GoalPlanID ?? "",
        };

        const res = await Api.put("/w39f3001/load-group-target", param);

        const dtGT = res?.data ?? [];
        setDataCboGroupTarget(dtGT);
    };

    const loadObjectRefs = () => {
        const params = {
            Type,
        };
        dispatch(
            W39F3002Actions.getObjectRef(params, err => {
                if (err) return Config.popup.show("ERROR", err);
            })
        );
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([loadData(), loadCboComputeMethod(), loadObjectRefs()]).then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const concurrent = data?.ConcurrentID || data?.ConcurrentID?.ConcurrentID;
        if (!isSetConcurrentID.current && concurrent !== "" && dataCboConcurrent?.length > 0) {
            isSetConcurrentID.current = true;
            const concurrentID = dataCboConcurrent.find(item => item.ConcurrentID === concurrent) || "";
            const dutyName =
                concurrentID?.DepartmentName || concurrentID?.DutyName
                    ? `${concurrentID?.DepartmentName || ""} - ${concurrentID?.DutyName || ""}`
                    : null;
            dataOld.current = { ...dataOld.current, DutyName: dutyName };
            setData({ ...data, ConcurrentID: concurrentID || "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.ConcurrentID, dataCboConcurrent]);

    const handleOpenPopup = (name, flag = false) => {
        switch (name) {
            case "W39F3015":
                if (flag && !data?.IsEditMethod) return;
                setIsOpenW39F3015Pop(flag);
                break;
            default:
                break;
        }
    };

    const handleChangeValue = (key, value, dt) => {
        switch (key) {
            case "Proportion":
                setData({ ...data, [key]: value });
                checkProportion(value);
                break;
            case "ValidDateTo":
            case "GroupGoalID":
                setData({ ...data, [key]: value });
                updateData(key, value);
                break;
            case "StrObjectiveRef":
                setData({ ...data, [dt?.RefID]: value });
                updateData(key, value, dt);
                break;
            case "ConcurrentID":
                if (!value) {
                    setError({ ...error, [key]: Config.lang("Truong_nay_bat_buoc_nhap") });
                } else {
                    setError({ ...error, [key]: "" });
                }
                setData({ ...data, [key]: value });
                break;
            case "CoeffObjective":
                setData({ ...data, [dt?.CoeffID]: value });
                updateData(key, value, dt);
                break;
            default:
                break;
        }
    };

    const checkProportion = value => {
        const params = {
            FormID: "W39F3002",
            GoalPlanID: data?.GoalPlanID ?? "",
            GroupGoalID: data?.GroupGoalID ?? "",
            GoalID: data?.GoalID ?? "",
            Proportion: value ?? "",
        };

        dispatch(
            W39F3002Actions.checkProportion(params, (err, dt) => {
                if (err) return Config.popup.show("ERROR", err);
                if (dt?.Status === 0) {
                    updateData("Proportion", value);
                } else if (dt?.Status === 1 && dt?.MsgAsk === 0) {
                    if (proportionRef.current) proportionRef.current.revert();
                    setData({ ...data, Proportion: originData.current?.Proportion ?? "" });
                    return Config.popup.show("ERROR", dt?.Message, () => {});
                }
            })
        );
    };

    const updateData = (key, value, dtU) => {
        let param = {
            GoalID: data?.GoalID ?? "",
        };

        switch (key) {
            case "Proportion":
            case "ValidDateTo":
            case "GroupGoalID":
                param = {
                    ...param,
                    [key]: value ?? "",
                };
                setLoading(true);
                dispatch(
                    W39F3002Actions.updateDetailInfo(param, (err, dt) => {
                        setLoading(false);
                        if (err) {
                            switch (key) {
                                case "Proportion":
                                    if (proportionRef.current) proportionRef.current.revert();
                                    setData({ ...data, [key]: originData.current?.[key] ?? "" });
                                    break;
                                case "ValidDateTo":
                                    setData({ ...data, [key]: originData.current?.[key] ?? "" });
                                    break;
                                case "GroupGoalID":
                                    if (groupTargetRef.current) groupTargetRef.current.setPreviousValue();
                                    setData({ ...data, [key]: originData.current?.[key] ?? "" });
                                    break;
                                default:
                                    break;
                            }
                            return Config.popup.show("ERROR", err);
                        }
                        switch (key) {
                            case "Proportion":
                                if (dt?.Status) {
                                    if (proportionRef.current) proportionRef.current.revert();
                                    setData({ ...data, [key]: originData.current?.[key] ?? "" });
                                    Config.notify.show("error", Config.lang("Cap_nhat_trong_so_that_bai"), 2000);
                                } else {
                                    originData.current = data;
                                    saveHistory(key, value);
                                    updateProportion();
                                    Config.notify.show("success", Config.lang("Cap_nhat_trong_so_thanh_cong"), 2000);
                                }
                                break;
                            case "ValidDateTo":
                                if (dt?.Status) {
                                    setData({ ...data, [key]: originData.current?.[key] ?? "" });
                                    Config.notify.show("error", Config.lang("Cap_nhat_ngay_hoan_thanh_that_bai"), 2000);
                                } else {
                                    originData.current = data;
                                    Config.notify.show(
                                        "success",
                                        Config.lang("Cap_nhat_ngay_hoan_thanh_thanh_cong"),
                                        2000
                                    );
                                }
                                break;
                            case "GroupGoalID":
                                if (dt?.Status) {
                                    if (groupTargetRef.current) groupTargetRef.current.setPreviousValue();
                                    setData({ ...data, [key]: originData.current?.[key] ?? "" });
                                    Config.notify.show("error", Config.lang("Cap_nhat_nhom_that_bai"), 2000);
                                } else {
                                    originData.current = data;
                                    Config.notify.show("success", Config.lang("Cap_nhat_nhom_thanh_cong"), 2000);
                                }
                                break;
                            default:
                                break;
                        }
                    })
                );
                break;
            case "StrObjectiveRef":
                dataDynamicRef.forEach(i => {
                    if (i?.IsUsed) {
                        param[i?.RefID ?? "strObjectRef"] =
                            i?.RefID === dtU?.RefID ? value : data?.[i?.RefID ?? "strObjectRef"] ?? "";
                    }
                });
                setLoading(true);
                dispatch(
                    W39F3002Actions.updateObjectRef(param, (err, dt) => {
                        setLoading(false);
                        if (err) {
                            return Config.popup.show("ERROR", err);
                        }

                        if (dt?.Status) {
                            const strObjectRef = StrObjectRefs.current?.[dtU?.RefID ?? "strObjectRef"] ?? null;
                            if (strObjectRef) strObjectRef.revert();
                            setData({
                                ...data,
                                [dtU?.RefID ?? "strObjectRef"]:
                                    originData.current?.[dtU?.RefID ?? "strObjectRef"] ?? "",
                            });
                            Config.notify.show(
                                "error",
                                Config.lang(`Cap_nhat_gia_tri_%${dtU?.Name ?? ""}%_that_bai`),
                                2000
                            );
                        } else {
                            originData.current = data;
                            saveHistory(key, value, dtU);
                            Config.notify.show(
                                "success",
                                Config.lang(`Cap_nhat_gia_tri_%${dtU?.Name ?? ""}%_thanh_cong`),
                                2000
                            );
                        }
                    })
                );
                break;
            case "CoeffObjective":
                originCoeffObjectives.current.forEach(i => {
                    if (i?.IsUsed) {
                        param[i?.CoeffID ?? "coeffObjective"] =
                            i?.CoeffID === dtU?.CoeffID ? value : data?.[i?.CoeffID ?? "coeffObjective"] ?? "";
                    }
                });
                setLoading(true);
                dispatch(
                    W39F3002Actions.updateCoeffObjective(param, (err, dt) => {
                        setLoading(false);
                        if (err) {
                            return Config.popup.show("ERROR", err);
                        }

                        if (dt?.Status) {
                            const coeffObjective =
                                CoeffObjectiveRefs.current?.[dtU?.CoeffID ?? "coeffObjective"] ?? null;
                            if (coeffObjective) coeffObjective.revert();
                            setData({
                                ...data,
                                [dtU?.CoeffID ?? "coeffObjective"]:
                                    originData.current?.[dtU?.CoeffID ?? "coeffObjective"] ?? "",
                            });
                            Config.notify.show(
                                "error",
                                Config.lang(`Cap_nhat_gia_tri_%${dtU?.Name ?? ""}%_that_bai`),
                                2000
                            );
                        } else {
                            originData.current = data;
                            saveHistory(key, value, dtU);
                            Config.notify.show(
                                "success",
                                Config.lang(`Cap_nhat_gia_tri_%${dtU?.Name ?? ""}%_thanh_cong`),
                                2000
                            );
                        }
                    })
                );
                break;
            default:
                break;
        }
    };

    const handleThousandSeparator = (value, flag = false) => {
        return flag ? value.toString().replace(/,/g, "") : Number(value).toLocaleString("en");
    };

    const saveHistory = async (key, value, dt) => {
        if (!data) return false;
        if (data?.GoalID) {
            let _data = {
                GoalObjectName: `${data?.GoalID ?? ""}-${data?.GoalName ?? ""}`,
            };

            let _dataOld = {
                GoalObjectName: `${data?.GoalID ?? ""}-${data?.GoalName ?? ""}`,
            };

            let captions = {
                GoalObjectName: "Ten_muc_tieu",
            };

            switch (key) {
                case "Proportion":
                    _data.Proportion = value ?? 0;
                    _dataOld.Proportion = originData.current?.Proportion ?? 0;
                    captions.Proportion = "Trong_so";
                    break;
                case "StrObjectiveRef":
                    dataDynamicRef.forEach(i => {
                        if (i?.IsUsed) {
                            _data[i?.RefID ?? "strObjectRef"] =
                                i?.RefID === dt?.RefID ? value : data?.[i?.RefID ?? "strObjectRef"] ?? "";
                            _dataOld[i?.RefID ?? "strObjectRef"] =
                                originData.current?.[i?.RefID ?? "strObjectRef"] ?? "";
                            captions[i?.RefID ?? "strObjectRef"] = i?.Name ?? "";
                        }
                    });
                    break;
                case "CoeffObjective":
                    _data[dt?.CoeffID ?? "coeffObjective"] = value;
                    _dataOld[dt?.CoeffID ?? "coeffObjective"] = handleThousandSeparator(
                        originData.current?.[dt?.CoeffID ?? "coeffObjective"] ?? ""
                    );
                    captions[dt?.CoeffID ?? "coeffObjective"] = dt?.Name ?? "";
                    break;
                default:
                    break;
            }

            const options = {
                data: _data,
                dataCompare: _dataOld,
                captions: captions,
                action: 1,
                ModuleID: "D39",
                TransID: data?.GoalID ?? "",
                TransactionID: "W39F3002",
                TransactionName: "Thông tin mục tiêu",
            };

            const history = new History(options);
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const saveHistory2 = async dt => {
        if (!dt) return false;
        const GoalID = dt?.GoalID || "";
        const dutyName =
            data?.ConcurrentID?.DepartmentName || data?.ConcurrentID?.DutyName
                ? `${data?.ConcurrentID?.DepartmentName || ""} - ${data?.ConcurrentID?.DutyName || ""}`
                : null;
        if (GoalID) {
            // data Content
            const _data = {
                GoalName: dt?.GoalID + " - " + data?.GoalName,
                IsConcurrent: isConcurrent ? Config.lang("Co") : Config.lang("Khong"),
                DutyName: dutyName,
            };

            //name Content
            const captions = {
                GoalName: "Ten_muc_tieu",
                IsConcurrent: "Kiem_nhiem",
                DutyName: "Chuc_vu_kiem_nhiem",
            };

            const _dataOld = dataOld.current;

            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: 1,
                ModuleID: "D39",
                TransID: GoalID,
                TransactionID: "W39F3002",
                TransactionName: "Thông tin mục tiêu",
            };
            const history = new History(options); //Init history
            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const getComputeMethodName = () => {
        const computeMethod = data?.ComputeMethod ?? "";
        const computeMethodObj = dataCboComputeMethod.find(i => i.ComputeMethod === computeMethod);
        return computeMethodObj?.ComputeMethodName ?? "";
    };

    const renderStrObjectRef = (i, idx) => {
        const isNum = i?.RefID.slice(0, 3) === "Num";
        if (!i?.IsUsed) return;
        return (
            <RowCustom key={idx}>
                <W39F3002TextEdit
                    ref={ref => (StrObjectRefs.current[i?.RefID ?? "strObjectRef"] = ref)}
                    classes={classes}
                    value={data?.[i?.RefID ?? "strObjectRef"]}
                    disabled={!data.IsProportionEdit}
                    type={isNum ? "number" : "string"}
                    thousandSeparator={isNum}
                    onSave={e => handleChangeValue("StrObjectiveRef", e, i)}
                    inputProps={{ label: i?.Name ?? "" }}
                />
            </RowCustom>
        );
    };

    const isDisabled = goalProps?.Type === "Object" && data?.IsAllEdit === 0 && data?.IsConcurrent === 1;
    const isRequired =
        (goalProps?.Type === "Object" && data?.IsBalanceObjectProportion === 0) ||
        (goalProps?.Type === "Key" && data?.IsBalanceKeyProportion === 0);
    const isDisabledProportion =
        (goalProps?.Type === "Object" && data?.IsBalanceObjectProportion === 1) ||
        (goalProps?.Type === "Key" && data?.IsBalanceKeyProportion === 1);

    const renderCoeffObjective = (i, idx) => {
        if (!i?.IsUsed) return null;
        return (
            <RowCustom key={i?.RefID ?? idx}>
                <W39F3002TextEdit
                    ref={ref => (CoeffObjectiveRefs.current[i?.CoeffID ?? "CoeffID"] = ref)}
                    classes={classes}
                    value={(data?.[i?.CoeffID ?? "CoeffID"] ?? "").toString()}
                    thousandSeparator
                    type={"number"}
                    onSave={e => handleChangeValue("CoeffObjective", e, i)}
                    disabled={!data.IsAllEdit}
                    inputProps={{ label: i?.Name }}
                />
            </RowCustom>
        );
    };

    return (
        <div id={"W39F3002Tab2"} className={classes.container}>
            <Loading visible={loading} />
            {data && Object.keys(data).length > 0 && (
                <Row>
                    <RowCustom className={"display_row align-center"}>
                        <Icon id={"Info"} name={"Info"} className={"mgr2x"} type={"filled"} viewBox />
                        <Typography type={"h3"}>{Config.lang("Chi_tiet")}</Typography>
                    </RowCustom>
                    <RowCustom>
                        <Typography type={"h3"}>
                            {Type === "Object" ? Config.lang("Thong_tin_muc_tieu") : Config.lang("Ket_qua_then_chot")}
                        </Typography>
                    </RowCustom>
                    <RowCustom>
                        <Label>{Config.lang("Bang_muc_tieu")}</Label>
                        <Typography type={"h3"}>{data?.GoalPlanName ?? ""}</Typography>
                    </RowCustom>
                    <RowCustom>
                        <DatePicker
                            label={Config.lang("Ngay_hoan_thanh")}
                            disabled={!data?.IsAllEdit}
                            onChange={e => handleChangeValue("ValidDateTo", e.value)}
                            placeholder={"dd/mm/yyyy"}
                            value={data?.ValidDateTo ?? ""}
                        />
                    </RowCustom>
                    <RowCustom>
                        <W39F3002TextEdit
                            ref={proportionRef}
                            value={`${data?.Proportion ?? ""}`}
                            onSave={e => handleChangeValue("Proportion", e)}
                            type={"number"}
                            isRequired={isRequired}
                            disabled={
                                !data.IsProportionEdit || data?.IsBalanceObjectProportion === 1 || isDisabledProportion
                            }
                            inputProps={{
                                label: Config.lang("Trong_so"),
                                max: 100,
                                min: 0,
                            }}
                        />
                    </RowCustom>
                    <RowCustom>
                        <Label type={"p2"}>{Config.lang("Phuong_phap_do")}</Label>
                        <Typography
                            type={"h3"}
                            color={"info"}
                            onClick={() => handleOpenPopup("W39F3015", true)}
                            fullWidth={false}
                        >
                            {getComputeMethodName()}
                        </Typography>
                    </RowCustom>
                    <RowCustom>
                        <Label>{Config.lang("Nguoi_tao")}</Label>
                        <Typography type={"h3"}>{data?.CreateUserName ?? ""}</Typography>
                    </RowCustom>
                    {Type !== "Key" && (
                        <RowCustom>
                            <Dropdown
                                label={Config.lang("Nhom")}
                                ref={ref => (groupTargetRef.current = ref)}
                                dataSource={dataCboGroupTarget}
                                displayExpr={"GroupGoalName"}
                                noDataText={Config.lang("Khong_co_du_lieu")}
                                onChange={e => handleChangeValue("GroupGoalID", e ? e.value : "")}
                                valueExpr={"GroupGoalID"}
                                value={data?.GroupGoalID ?? 0}
                                disabled={!data?.IsAllEdit}
                            />
                        </RowCustom>
                    )}
                    <RowCustom>
                        <Label>{Config.lang("Ngay_tao")}</Label>
                        <Typography type={"h3"}>{Config.convertDate(data?.CreateDate, "", "DD/MM/YYYY")}</Typography>
                    </RowCustom>
                    {isCheckEmployeeConcurrent && goalProps?.Type === "Object" && (
                        <>
                            <RowCustom>
                                <Checkbox
                                    label={Config.lang("Tao_muc_tieu_cho_chuc_danh_kiem_nhiem")}
                                    checked={isConcurrent}
                                    disabled={goalProps?.Type === "Object" && !data?.IsAllEdit}
                                    onChange={e => {
                                        setIsConcurrent(e.target.checked);
                                        setIsChanged(true);
                                    }}
                                />
                            </RowCustom>
                            {isConcurrent && (
                                <>
                                    <RowCustom style={{ position: "relative" }}>
                                        <Label className={"display_row align-center"}>
                                            <div className={"display_row align-center"}>
                                                {Config.lang("Thong_tin_kiem_nhiem")}
                                                <Tooltip
                                                    direction={"top"}
                                                    disabled={isDisabled}
                                                    tooltipMaxWidth={400}
                                                    title={Config.lang(
                                                        "Nhan_vien_kiem_nhiem_nhieu_chuc_danh.Xac_dinh_muc_tieu_cho_chuc_danh_nao_de_quy_trinh_duyet/review_muc_tieu_gui_den_dung_cap_co_tham_quyen"
                                                    )}
                                                >
                                                    <Icon
                                                        name={"info"}
                                                        width={16}
                                                        height={16}
                                                        className={"mgl1x"}
                                                        viewBox
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Label>
                                        <Combo.LoadMore
                                            error={error && error["ConcurrentID"]}
                                            dataSource={dataCboConcurrent}
                                            totalItems={dataCboConcurrent.length}
                                            keyExpr={"ConcurrentID"}
                                            valueExpr={"ConcurrentID"}
                                            loading={loading}
                                            acceptCustomValue={false}
                                            value={data.ConcurrentID}
                                            required={true}
                                            margin={"none"}
                                            displayExpr={data => {
                                                if (!data) return null;
                                                return data?.DepartmentName + " - " + data?.DutyName;
                                            }}
                                            disabled={isDisabled}
                                            showColumnHeaders={true}
                                            stylingMode={"underlined"}
                                            showClearButton={true}
                                            onValueChanged={e => handleChangeValue("ConcurrentID", e ? e.value : "")}
                                        >
                                            {columnCBo?.current?.map((field, idxField) => {
                                                return (
                                                    <Column
                                                        key={idxField}
                                                        dataField={field.FieldName}
                                                        caption={field.Caption}
                                                    />
                                                );
                                            })}
                                        </Combo.LoadMore>
                                        {isChanged && (
                                            <div className={`display_row ${classes.styleBtnAction}`}>
                                                <ButtonIcon
                                                    color={"success"}
                                                    viewType={"filled"}
                                                    name={"Approval"}
                                                    size={"tiny"}
                                                    id={"approval"}
                                                    className={"mgr2x"}
                                                    onClick={onUpdate}
                                                />
                                                <ButtonIcon
                                                    color={"danger"}
                                                    viewType={"filled"}
                                                    name={"Cancel"}
                                                    size={"tiny"}
                                                    id={"cancel"}
                                                />
                                            </div>
                                        )}
                                    </RowCustom>
                                </>
                            )}
                        </>
                    )}
                    {coeffObjectives?.map((i, idx) => renderCoeffObjective(i, idx))}
                    {dataDynamicRef.map((i, idx) => renderStrObjectRef(i, idx))}
                </Row>
            )}
            {isOpenW39F3015Pop && (
                <W39F3015
                    open={isOpenW39F3015Pop}
                    handleOpenPopup={handleOpenPopup}
                    dataCboComputeMethod={dataCboComputeMethod}
                    goalProps={{ ...goalProps, Type: goalProps.Type || data.Type }}
                />
            )}
        </div>
    );
}

const RowCustom = ({ children, ...rest }) => {
    return (
        <>
            <Col xs={0} sm={0} md={2} lg={3} />
            <Col xs={12} sm={12} md={8} lg={6} {...rest}>
                {children}
            </Col>
            <Col xs={0} sm={0} md={2} lg={3} />
        </>
    );
};

export default W39F3002Tab2;
